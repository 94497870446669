import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Subscription } from 'rxjs';
import { FormValidatorsService } from '../form-validators.service';
import { GetPasswordErrors } from '../registration/registration.component';
import { CookiesService } from '../shared/service/cookies';
import { DemoModeService } from '../demo/demo-mode.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public confirmPassword: string | null = null;
  public forgotPasswordCode: string | null = null;
  public errorMessage: string | null = null;
  public inviteData: string | undefined = undefined;

  public isLoading = false;
  public isError = false;
  public isPasswordReset = false;
  public isPasswordChange = false;

  public loginFormGroup: FormGroup;
  public resetPasswordFormGroup: FormGroup;

  private passwordChangesSub: Subscription | null = null;

  public queryParams: Params;

  public passwordErrorHandler = GetPasswordErrors;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private validatorService: FormValidatorsService,
    private route: ActivatedRoute,
    private cookieService: CookiesService,
    private demoModeService: DemoModeService,
  ) {

    this.queryParams = { ...this.route.snapshot.queryParams };

    this.loginFormGroup = this.fb.group({
      email: validatorService.GetRequiredFormControl(),
      password: validatorService.GetRequiredFormControl(),
    });

    this.resetPasswordFormGroup = this.fb.group({
      code: validatorService.GetRequiredFormControl(),
      password: validatorService.GetPasswordFormControl(),
      passwordConfirmation: validatorService.GetRequiredFormControl(),
    });
  }

  ngOnInit(): void {
    this.collectInvitationData();
    this.subscribeToPasswordChanges(this.resetPasswordFormGroup);
  }

  ngOnDestroy(): void {
    if (this.passwordChangesSub && !this.passwordChangesSub.closed) {
      this.passwordChangesSub.unsubscribe();
    }
  }

  public async login(): Promise<void> {
    if (this.loginFormGroup.invalid) {
      this.touchFields(this.loginFormGroup);
      return;
    }

    this.isLoading = true;
    const email = this.getEmail();
    const password = this.loginFormGroup.controls.password.value;

    try { 
      this.cookieService.removeCognitoCookies();
      await Auth.signIn(email.toLowerCase(), password);
      this.demoModeService.updateDemoModeStatus();
      await this.router.navigate(['/dashboard'], { queryParams: this.queryParams });
    } catch (error) {
      this.errorMessage = this.makePrettyErrorMessage(error);
      this.isError = true;
      this.isLoading = false;
    }
  }

  private collectInvitationData(): void {
    const inviteId = this.route.snapshot.queryParamMap.get('invite_id');
    const companyId = this.route.snapshot.queryParamMap.get('company_id');

    if (inviteId && companyId) {
      this.inviteData = JSON.stringify({ invite_id: inviteId, company_id: companyId });
    }
  }

  private touchFields(formGroup: FormGroup): void {
    formGroup.markAllAsTouched();
  }

  public async googleLogin(): Promise<void> {
    try {
      this.cookieService.removeCognitoCookies();
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: this.inviteData });
    } catch (error) {
      this.errorMessage = this.makePrettyErrorMessage(error);
      this.isError = true;
    }
  }

  public async linkedInLogin(): Promise<void> {
    try {
      this.cookieService.removeCognitoCookies();
      await Auth.federatedSignIn({ customProvider: 'LinkedIn', customState: this.inviteData });
    } catch (error) {
      this.errorMessage = this.makePrettyErrorMessage(error);
      this.isError = true;
    }
  }

  public backToSignIn(): void {
    this.errorMessage = '';
    this.isPasswordReset = false;
    this.isPasswordChange = false;
    this.loginFormGroup.reset();
    this.resetPasswordFormGroup.reset();
  }

  public forgotPassword(): void {
    this.errorMessage = '';
    this.isPasswordReset = true;
  }

  public async sendResetCode(): Promise<void> {
    this.loginFormGroup.controls.password.setValue('ResetPassword');
    if (this.loginFormGroup.invalid) {
      this.touchFields(this.loginFormGroup);
      return;
    }
    const email = this.getEmail();
    this.isLoading = true;

    try {
      await Auth.forgotPassword(email.toLocaleLowerCase());
      this.subscribeToPasswordChanges(this.resetPasswordFormGroup);
      this.isPasswordChange = true;
      this.isLoading = false;
      this.isError = false;
    } catch (err) {
      this.errorMessage = this.makePrettyErrorMessage(err);
      this.isError = true;
      this.isLoading = false;
    }
  }

  private subscribeToPasswordChanges(formGroup: FormGroup): void {
    const passwordConfirmationControl = formGroup.controls.passwordConfirmation;
    const passwordControl = formGroup.controls.password;

    this.passwordChangesSub = passwordConfirmationControl.valueChanges.subscribe(value => {
      if (passwordControl.value !== value) {
        passwordConfirmationControl.setErrors({ passwordMismatch: true });
      } else {
        passwordConfirmationControl.setErrors(null);
      }
    });

    this.passwordChangesSub.add(passwordControl.valueChanges.subscribe(value => {
      if (value !== passwordConfirmationControl.value) {
        passwordConfirmationControl.setErrors({ passwordMismatch: true });
      } else {
        passwordConfirmationControl.setErrors(null);
      }
    }));
  }

  public async resetPassword(): Promise<void> {
    if (this.resetPasswordFormGroup.invalid) {
      this.touchFields(this.resetPasswordFormGroup);
      return;
    }
    this.isLoading = true;

    const code = this.resetPasswordFormGroup.controls.code.value;
    const password = this.resetPasswordFormGroup.controls.password.value;
    const email = this.getEmail();

    try {
      await Auth.forgotPasswordSubmit(email.toLocaleLowerCase(), code, password);
      this.isLoading = false;
      this.backToSignIn();
    } catch (error) {
      this.errorMessage = this.makePrettyErrorMessage(error);
      this.isError = true;
      this.isLoading = false;
    }
  }

  private makePrettyErrorMessage(error: unknown): string {
    const errorMsg = error as AmplifyErrorMessage;
    return errorMsg.message;
  }

  private getEmail(): string {
    return this.loginFormGroup.controls.email.value;
  }
}

export interface AmplifyErrorMessage {
  code: string;
  message: string;
}
