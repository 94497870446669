import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription, interval } from 'rxjs';
import { Status } from '../../../../exercise.component';
import { DemoExerciseDataService } from 'src/app/exercise/demo/services/demo-exercise-data/demo-exercise-data.service';
import { IExerciseDataService } from 'src/app/exercise/demo/models/exercise-data-interface';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  private websocketMessageSub: Subscription = new Subscription();
  private tickerSubscription: Subscription = new Subscription();
  private shouldStopTimer = true;
  private data!: IExerciseDataService;
  public remainingTime = 0;

  constructor(
    private exerciseData: ExerciseDataService,
    private demoExerciseData: DemoExerciseDataService,
    private demoModeService: DemoModeService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.data = await this.demoModeService.isDemo ? this.demoExerciseData : this.exerciseData;
    this.handleWebSocketMessage();
    this.startTimer();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
    this.tickerSubscription.unsubscribe();
  }

  private startTimer(): void {
    this.tickerSubscription = interval(1000).subscribe(() => {
      if (this.shouldStopTimer) {
        return;
      }

      if (this.remainingTime == 0) {
        return;
      }

      this.remainingTime--;
    });
  }

  private handleWebSocketMessage(): void {
    this.websocketMessageSub = this.data.ws.messages$.subscribe(resp => {
      if (resp.timer) {
        this.remainingTime = resp.timer;
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.shouldStopTimer = false;
        return;
      }

      if (resp.loading_message ||
        resp.exercise?.environmentStatus == Status.Canceled ||
        resp.exercise?.validationTestsStatus == Status.Completed ||
        resp.fatal_error
      ) {
        this.shouldStopTimer = true;
      }
    });
  }
}
