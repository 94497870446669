import { MonacoTreeElement } from 'ngx-monaco-tree';
import { FileSystemNode } from './file-system-node';
import { Node } from './models/node.interface';

export class Tree {
  private root: FileSystemNode;
  private monacoTree: MonacoTreeElement[] = [];

  constructor(nodes: Node[]) {
    this.root = new FileSystemNode(nodes);
    this.toMonacoTreeElement();
  }

  get tree(): MonacoTreeElement[] {
    return this.monacoTree;
  }

  public DeleteFile(path: string): void {
    this.root.DeleteFile(path);
    this.toMonacoTreeElement();
  }

  public AddFile(file: Node) {
    if (!file.path) {
      return;
    }

    this.root.AddFile(file.name, file.path, file.isDir ?? false);
    this.toMonacoTreeElement();
  }

  public Find(path: string): FileSystemNode | undefined {
    return this.root.Find(path);
  }

  public isDirectory(path: string): boolean {
    return this.root.isDirectory(path);
  }

  public toMonacoTreeElement(): void {
    this.monacoTree = this.root.toMonacoTreeElement() ?? [];
  }
}