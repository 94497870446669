import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { diff_match_patch } from 'diff-match-patch';
import { Command, EditorEvent, Message, Status } from '../../../../exercise.component';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import * as monaco from 'monaco-editor';

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements OnInit, OnDestroy {
  @Output() isOpenChange = new EventEmitter<boolean>();

  public readonly dialogKey: string = 'close-code-editor-warning';
  private fontSizeEventSub: Subscription = new Subscription();
  private colorEventSub: Subscription = new Subscription();
  private websocketMessageSub: Subscription = new Subscription();
  private dmp: diff_match_patch = new diff_match_patch();
  private monacoEditor: monaco.editor.IStandaloneCodeEditor | null = null;
  public editorOptions: EditorOptions | null = null;
  public lastOpenedFile: EditorFile | null = null;
  public fileText = '';
  public canOpen = false;

  constructor(
    private _data: ExerciseDataService,
    private _confirmService: ConfirmationService
  ) { }

  private get isFileChanged(): boolean {
    return this.fileText !== this.lastOpenedFile?.data;
  }

  public ngOnInit(): void {
    this.handleWebSocketMessage();
  }

  public ngOnDestroy(): void {
    this.colorEventSub.unsubscribe();
    this.websocketMessageSub.unsubscribe();
    this.fontSizeEventSub.unsubscribe();
    this.closeMonacoEditor();
  }

  public onEditorInit(editor: monaco.editor.IStandaloneCodeEditor): void {
    this.monacoEditor = editor;
    this.handleColorEvent(editor);
    this.handleFontSizeEvent(editor);
    editor.addCommand(window.monaco.KeyMod.CtrlCmd | 49, () => this.saveFile());
    editor.onKeyDown(() => this.saveFile());
  }

  public closeEditor(): void {
    this.isFileChanged ? this.confirmSaveBeforeClosing() : this.closeMonacoEditor();
  }

  private closeMonacoEditor(): void {
    this.monacoEditor?.dispose();
    this.monacoEditor = null;
    this.emitCloseEvent();
  }

  private confirmSaveBeforeClosing(): void {
    this._confirmService.confirm({
      dismissableMask: true,
      header: 'Save changes?',
      message: `Do you want to save the changes you made to ${this.lastOpenedFile?.name}?`,
      key: this.dialogKey,
      accept: () => {
        this.saveFile();
        this.closeMonacoEditor();
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
        case ConfirmEventType.REJECT:
          this.closeMonacoEditor();
          break;
        case ConfirmEventType.CANCEL:
          return;
        }
      }
    });
  }

  private saveFile(): void {
    if (!this.lastOpenedFile || !this.isFileChanged) {
      return;
    }

    const diffText = this.calculateDiffText(this.lastOpenedFile.data, this.fileText);
    this.sendNewFileData(this.lastOpenedFile.path, diffText);
    this.lastOpenedFile.data = this.fileText;
  }

  private calculateDiffText(oldFile: string | undefined, changedFile: string): string {
    oldFile = oldFile ?? '';
    const diffs = this.dmp.diff_main(oldFile, changedFile);
    const patch_list = this.dmp.patch_make(oldFile, diffs);
    return this.dmp.patch_toText(patch_list);
  }

  private openCodeEditor(file: EditorFile): void {
    if (!this.canOpen) {
      return;
    }

    const name = file.path.split('/').pop() ?? '';
    const extension = name.slice(name.lastIndexOf('.'));

    this.fileText = file.data ?? '';
    this.lastOpenedFile = {
      name,
      extension,
      path: file.path,
      data: file.data,
    };

    this.editorOptions = {
      theme: this._data.theme == 'light' ? 'vs-light' : 'vs-dark',
      language: this.getLanguage(extension),
      fontSize: Number(this._data.fontSize),
      minimap: { enabled: false },
      editorBackground: '',
      automaticLayout: true,
    };
  }

  private getLanguage(fileExtension: string): string {
    const languages = window.monaco.languages.getLanguages();
    return languages.find(lang => lang.extensions?.includes(fileExtension))?.id ?? 'text/plain';
  }

  private handleColorEvent(editor: monaco.editor.IStandaloneCodeEditor): void {
    this.colorEventSub = this._data.theme$.subscribe(event => {
      if (this.editorOptions) {
        this.editorOptions.theme = event === 'light' ? 'vs-light' : 'vs-dark';
        editor.updateOptions(this.editorOptions);
      }
    });
  }

  private handleFontSizeEvent(editor: monaco.editor.IStandaloneCodeEditor): void {
    this.fontSizeEventSub = this._data.fontSize$.subscribe(event => {
      if (this.editorOptions) {
        this.editorOptions.fontSize = Number(event);
        editor.updateOptions(this.editorOptions);
      }
    });
  }

  private sendNewFileData(path: string, text: string): void {
    const message: Message = {
      command: Command.Editor,
      editor: {
        type: EditorEvent.SaveFile,
        file: { data: text, path: path }
      }
    };
    this._data.ws.sendMessage(JSON.stringify(message));
  }

  private emitCloseEvent(): void {
    this.isOpenChange.emit(false);
  }

  private handleWebSocketMessage(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.file) {
        this.isOpenChange.emit(true);
        this.openCodeEditor(resp.file);
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.canOpen = true;
      }

      if (
        resp.fatal_error ||
        resp.loading_message ||
        resp.exercise?.environmentStatus == Status.Canceled ||
        resp.exercise?.environmentStatus == Status.Completed
      ) {
        this.canOpen = false;
        this.emitCloseEvent();
      }
    });
  }
}

export interface EditorFile {
  path: string
  name?: string
  data?: string
  extension?: string
}

export interface EditorOptions {
  theme: string;
  minimap: { enabled: boolean };
  editorBackground: string;
  language?: string;
  automaticLayout: boolean;
  fontSize: number
  readOnly?: boolean
}

declare global {
  interface Window {
    monaco: typeof import('monaco-editor');
  }
}