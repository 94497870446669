import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WebsocketService } from './services/websocket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExerciseDataService } from './services/exercise-data.service';
import { Hint } from './components/sidebar/components/hint/hint.component';
import { EditorFile } from './components/workspace/components/code-editor/code-editor.component';
import { Subscription } from 'rxjs';
import { FileEvent } from './components/sidebar/components/file-watcher/models/file-event.interface';
import { Node } from './components/sidebar/components/file-watcher/models/node.interface';
import { DemoExerciseDataService } from './demo/services/demo-exercise-data/demo-exercise-data.service';
import { IExerciseDataService } from './demo/models/exercise-data-interface';
import { DemoModeService } from '../demo/demo-mode.service';


@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
  providers: [WebsocketService]
})
export class ExerciseComponent implements OnInit, OnDestroy {
  private websocketMessageSub: Subscription = new Subscription();
  private data!: IExerciseDataService;
  public websocket!: WebsocketService;
  public enumStatus = Status;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private renderer: Renderer2,
    private exerciseData: ExerciseDataService,
    private demoExerciseData: DemoExerciseDataService,
    private demoModeService: DemoModeService
  ) { }

  private get urlId(): string {
    return this.route.snapshot.paramMap.get('urlId') ?? '';
  }

  private get exerciseId(): string {
    return this.route.snapshot.paramMap.get('exerciseId') ?? '';
  }

  private get assignedPlanId(): string {
    return this.route.snapshot.paramMap.get('planId') ?? '';
  }

  async ngOnInit(): Promise<void> {
    this.data = await this.demoModeService.isDemo  ? this.demoExerciseData : this.exerciseData;
    
    this.disableGlobalScrolling();
    this.data.changeTheme(this.data.theme);

    try {
      await this.data.load(this.exerciseId);
    } catch (err) {
      const errMsg = err as Error;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errMsg.message });
      this.returnToPlanList();
    }

    try {
      this.websocket = await this.data.createConnection(this.assignedPlanId, this.exerciseId, this.urlId);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error! Please reload the page...' });
      return;
    }

    this.handleWebsocketMessages();
  }

  async ngOnDestroy(): Promise<void> {
    this.websocket?.disconnect();
    this.enableGlobalScrolling();
    this.websocketMessageSub.unsubscribe();
  }

  private handleWebsocketMessages(): void {
    this.websocketMessageSub = this.websocket.messages$.subscribe(async resp => {
      if (
        resp.exercise?.validationTestsStatus == Status.Completed ||
        resp.exercise?.environmentStatus == Status.TimeIsOver ||
        resp.exercise?.environmentStatus == Status.Canceled
      ) {
        this.fetchNextExercise();
        this.websocket.disconnect();
      }
    });
  }

  public async fetchNextExercise(): Promise<void> {
    try {
      this.data.loadNextExerciseData(this.assignedPlanId, this.exerciseId);
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Oops, we couldn\'t load the next exercise. Please press the exit button to close.'
      });
    }
  }

  private returnToPlanList(): void {
    this.router.navigate(['/plans']);
  }

  private disableGlobalScrolling(): void {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  private enableGlobalScrolling(): void {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }
}

export interface Response {
  type: Type
  error?: string
  fatal_error?: string
  init_tree?: Node[]
  file_event?: FileEvent
  file?: EditorFile
  terminal?: Terminal
  hint?: Hint
  timer?: number
  description?: string;
  loading_message?: string;
  exercise?: ExerciseStatuses
}

export interface Message {
  terminal?: TerminalResponse
  editor?: EditorResponse
  command: Command
}

export interface TerminalResponse {
  resize?: ResizeData,
  input?: string
  event_type: TerminalEvent
}

export interface ResizeData {
  width: number
  height: number
}

export interface EditorResponse {
  file: File
  new_file?: File
  type: EditorEvent
}

export interface File {
  data?: string
  path: string
}

interface ExerciseStatuses {
  validation_message?: string
  environmentStatus?: string;
  validationTestsStatus?: string;
}

interface Terminal {
  status: Status;
  output?: string;
}

export enum Command {
  Start = 'start',
  Cancel = 'cancel',
  Editor = 'editor',
  Restart = 'restart',
  GetHint = 'get_hint',
  Validate = 'validate',
  Terminal = 'terminal',
  ReferenceSolution = 'reference_solution',
}

export enum EditorEvent {
  GetFile = 'get_file',
  SaveFile = 'update_file',
  DeleteFile = 'delete_file',
  CreateFile = 'create_file',
  RenameFile = 'rename_file',
  CreateDir = 'create_dir'
}

export enum TerminalEvent {
  Input = 'input',
  Resize = 'resize',
}

export enum Type {
  InitTree = 'init_tree',
  Event = 'file_event',
  GetFile = 'get_file',
  UpdateFile = 'update_file',
  DeleteFile = 'delete_file',
  Error = 'error',
  Terminal = 'terminal',
  Hint = 'hint',
  Timer = 'timer',
  ExerciseStatus = 'exercise_status'
}

export enum Status {
  Success = 'Success',
  Running = 'Running',
  Completed = 'Completed',
  Starting = 'Starting',
  Failed = 'Failed',
  Created = 'Created',
  Restarted = 'Restarted',
  InstallationError = 'Installation Error',
  TimeIsOver = 'Time is over',
  NotStarted = 'Not Started',
  Canceled = 'Canceled',
  Rejected = 'Rejected'
}
