import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, from } from 'rxjs';
import { delay, takeWhile, concatMap, finalize } from 'rxjs/operators';
import { CodeEditorRecord, S3Service } from '../../s3.service';
import { TerminalService } from '../../terminal.service';
import { Exercise } from '../../exercise.module';
import { DynamoDBService } from '../../dynamodb.service';
import { Record } from '../../s3.service';
import { ExerciseStatus } from '../../models/exercise-status.model';
import { AssignedPlan } from '../../models/assigned-plan.model';
import { diff_match_patch } from 'diff-match-patch';
import { EditorOptions } from '../../exercise/components/workspace/components/code-editor/code-editor.component';
import { MonacoEditorHelperService } from '../services/monaco-editor-helper.service';
import * as monaco from 'monaco-editor';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-replay',
  templateUrl: './replay.component.html',
  styleUrls: ['./replay.component.scss']
})
export class ReplayComponent implements OnInit {
  public companyId: string | null = null;
  public assignedPlanId: string | null = null;
  public exerciseId: string | null = null;
  public userEmail: string | null = null;
  public base64UserEmail: string | null = null;
  public candidateName: string | undefined;

  public exerciseStatus: ExerciseStatus | null = null;
  public exercise: Exercise | null = null;
  public assignedPlan: AssignedPlan | null = null;

  public currentTimestamp = '';

  public isInfoShowed = false;
  public recordsExist = false;
  public startReplay = false;
  public isPaused = true;
  public speed = 1;
  public recordLength = 0;

  public delay = 300;
  public timeIndex = 0;

  public records: Record[] = [];

  public terminal: TerminalService = new TerminalService();

  public error: string | null = null;
  public loadingMsg: string | null = 'Loading...';

  private dmp: diff_match_patch = new diff_match_patch();

  public isTerminalClosed = true;
  public isCodeEditorClosed = true;
  private monacoEditor: monaco.editor.IStandaloneCodeEditor | null = null;
  public editorOptions: EditorOptions | null = null;

  private isDemo = false;

  constructor(
    private route: ActivatedRoute,
    private dynamoDBService: DynamoDBService,
    private monacoHelperService: MonacoEditorHelperService,
    private s3: S3Service,
    private demoModeService: DemoModeService
  ) { }

  async ngOnInit(): Promise<void> {
    document.body.setAttribute('data-theme', 'dark');
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.assignedPlanId = this.route.snapshot.paramMap.get('assignedPlanId');
    this.exerciseId = this.route.snapshot.paramMap.get('exerciseId');
    this.base64UserEmail = this.route.snapshot.paramMap.get('base64Email');

    if (!this.base64UserEmail ||
      !this.companyId ||
      !this.assignedPlanId ||
      !this.exerciseId) {
      this.error = 'Failed to load the replay. Please try again later';
      return;
    }

    try {
      this.userEmail = atob(this.base64UserEmail);
      this.isDemo = await this.demoModeService.isDemo;
      await Promise.all([
        this.fetchExercise(),
        this.fetchExerciseStatus(),
        this.fetchAssignedPlan(),
        this.fetchRecord()
      ]);
    } catch (err) {
      this.error = 'Records not found. Please try again later';
    }

    try {
      this.recordsExist = true;
      this.recordLength = this.records.length;
      this.loadingMsg = null;
      this.terminal.create();
      this.terminal.cols = this.getMaxWindowWidth(this.records);
    } catch (err) {
      this.error = 'Failed to create terminal. Please try again later';
    }

    this.setupCodeEditorOptions();
  }

  private async fetchExercise(): Promise<void> {
    this.exercise = await this.dynamoDBService.getExercise(this.exerciseId!);
  }

  private async fetchExerciseStatus(): Promise<void> {
    this.exerciseStatus = await this.dynamoDBService.getInterviewerExerciseStatus(this.assignedPlanId!, this.userEmail!, this.companyId!, this.exerciseId!);
  }

  private async fetchAssignedPlan(): Promise<void> {
    this.assignedPlan = await this.dynamoDBService.getAssignedPlan(this.userEmail!, this.companyId!, this.assignedPlanId!);
    this.candidateName = this.assignedPlan.candidate.fullNameOrEmail;
  }

  private async fetchRecord(): Promise<void> {
    const records = this.isDemo ?
      await this.s3.getDemoRecords(this.exerciseId!) :
      await this.s3.getRecords(this.companyId!, this.assignedPlanId!, this.exerciseId!, this.base64UserEmail!);

    if (!records) {
      throw Error('No records');
    }
    this.records = records;
  }

  private async writeRecords(records: Record[]): Promise<void> {
    if (this.timeIndex == 0)
      this.terminal.clearTerminal();

    if (!records) {
      return;
    }

    const source$ = from(records);

    source$.pipe(
      concatMap((record) => {
        this.timeIndex++;
        this.currentTimestamp = record.time.substring(0, 19);

        if (record.terminal)
          this.writeTerminalRecords(record.terminal.output);

        if (record.code_editor)
          this.writeCodeEditorRecords(record.code_editor);

        return of(record).pipe(delay(this.delay));
      }),
      takeWhile(() => !this.isPaused && this.timeIndex <= this.recordLength),
      finalize(() => {
        if (this.timeIndex == this.recordLength - 1) {
          this.terminal.write('');
        }
        this.isPaused = true;
      })
    ).subscribe();
  }

  public async togglePause(): Promise<void> {
    this.startReplay = true;
    this.isPaused = !this.isPaused;
    if (this.records && !this.isPaused) {
      if (this.timeIndex == this.recordLength - 1)
        this.timeIndex = 0;
      this.writeRecords(this.records.slice(this.timeIndex, -1));
    }
  }

  public changeSpeed(speed: GLfloat): void {
    switch (speed) {
    case 1: {
      if (this.speed < 10) {
        if (this.speed > 1)
          this.speed += 1;
        else
          this.speed *= 2;
      }
      break;
    }
    case -1: {
      if (this.speed > 0.25) {
        if (this.speed > 1)
          this.speed -= 1;
        else
          this.speed /= 2;
      }
      break;
    }
    }
    this.delay = 300 / this.speed;
  }

  public async onSliderChange(): Promise<void> {
    if (!this.isPaused)
      this.togglePause();
    this.fastRecordWrite(this.records.slice(0, this.timeIndex));
  }

  public fastRecordWrite(records: Record[]): void {
    this.terminal.clearTerminal();
    this.terminal.write('\n');
    this.monacoEditor?.setValue('');

    for (const record of records) {
      if (record.terminal)
        this.writeTerminalRecords(record.terminal.output);

      if (record.code_editor)
        this.writeCodeEditorRecords(record.code_editor);
    }
  }

  private writeTerminalRecords(record: string): void {
    this.isCodeEditorClosed = true;
    this.isTerminalClosed = false;
    this.terminal.write(record);
  }

  private writeCodeEditorRecords(record: CodeEditorRecord): void {
    this.isCodeEditorClosed = false;
    this.isTerminalClosed = true;

    if (!record.is_diff) {
      if (record.output)
        this.monacoEditor?.setValue(record.output);

      const extension = record.file_name.slice(record.file_name.lastIndexOf('.'));
      this.monacoHelperService.changeLanguage(this.monacoEditor!, extension);
    }

    if (record.is_diff) {
      const patches = this.dmp.patch_fromText(record.output);
      const positionStart = this.monacoEditor!.getModel()!.getPositionAt(patches[0].start1! + patches[0].length1);
      this.monacoHelperService.applyPatch(this.monacoEditor!, patches[0]);
      this.monacoEditor!.revealLineInCenter(positionStart.lineNumber);
    }
  }

  private getMaxWindowWidth(record: Record[]): number {
    return record.reduce((maxWidth, current) => {
      if (current.terminal)
        return Math.max(maxWidth, current.terminal.rows ?? 0);
      else
        return 0;
    }, 0);
  }

  public onEditorInit(editor: monaco.editor.IStandaloneCodeEditor): void {
    this.monacoEditor = editor;
  }

  private setupCodeEditorOptions(): void {
    this.editorOptions = {
      theme: 'vs-dark',
      language: 'text/plain',
      fontSize: 14,
      minimap: { enabled: false },
      editorBackground: '',
      automaticLayout: true,
      readOnly: true,
    };
  }
}
