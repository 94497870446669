<section 
  class="w-100">
    <div 
      class="description" 
      *ngIf="this.data && this.data.exercise"
      [innerHTML]="this.data.exercise.description | markdown:dynamicDescription">
    </div>
    <div 
      class="scroller"
      *ngIf="!this.data || !this.data.exercise"> 
        <p-progressSpinner></p-progressSpinner>
    </div>
</section>
