import { Injectable } from '@angular/core';
import { Diff, DIFF_DELETE, DIFF_EQUAL, DIFF_INSERT, patch_obj } from 'diff-match-patch';
import * as monaco from 'monaco-editor';

@Injectable({
  providedIn: 'root'
})
export class MonacoEditorHelperService {

  public applyPatch(editor: monaco.editor.IStandaloneCodeEditor, patch: patch_obj): void {
    const model: monaco.editor.ITextModel | null = editor.getModel();
    const diffs: Diff[] = patch.diffs;
    let offset: number = patch.start1 ?? 0;

    if (!model)
      return;

    diffs.forEach((diff: Diff) => {
      const [operation, text] = diff;
      let startPos: monaco.Position;
      let endPos: monaco.Position;
      let insertPos: monaco.Position;
      let range: monaco.Range;
      let editOperation: monaco.editor.IIdentifiedSingleEditOperation | null = null;

      switch (operation) {
      case DIFF_EQUAL:
        offset += text.length;
        break;

      case DIFF_DELETE:
        startPos = model.getPositionAt(offset);
        endPos = model.getPositionAt(offset + text.length);
        range = new window.monaco.Range(startPos.lineNumber, startPos.column, endPos.lineNumber, endPos.column);
        editOperation = {
          range: range,
          text: ''
        };
        break;

      case DIFF_INSERT:
        insertPos = model.getPositionAt(offset);
        range = new window.monaco.Range(insertPos.lineNumber, insertPos.column, insertPos.lineNumber, insertPos.column);
        editOperation = {
          range: range,
          text: text
        };
        offset += text.length;
        break;
      }

      if (editOperation) {
        model.pushEditOperations([], [editOperation], () => null);
      }
    });
  }

  public changeLanguage(editor: monaco.editor.IStandaloneCodeEditor, extension: string): void {
    const languages = window.monaco.languages.getLanguages();
    const fileLanguage = languages.find(lang => lang.extensions?.includes(extension))?.id ?? 'text/plain';
    window.monaco.editor.setModelLanguage(editor.getModel()!, fileLanguage);
  }
}
