import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { Command, Message, Status } from 'src/app/exercise/exercise.component';
import { DemoExerciseDataService } from 'src/app/exercise/demo/services/demo-exercise-data/demo-exercise-data.service';
import { IExerciseDataService } from 'src/app/exercise/demo/models/exercise-data-interface';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit, OnDestroy {
  private websocketMessageSub: Subscription = new Subscription();
  private isLastHint = false;
  private hasError = false;
  public isLoading = true;
  public hints: string[] = [];
  public data!: IExerciseDataService;

  constructor(
    private _exerciseData: ExerciseDataService,
    private _demoExerciseData: DemoExerciseDataService,
    private _demoModeService: DemoModeService
  ) { }

  get isButtonDisabled(): boolean {
    return this.isLastHint || this.hasError;
  }

  public async ngOnInit(): Promise<void> {
    this.data = await this._demoModeService.isDemo ? this._demoExerciseData : this._exerciseData;
    this.handleWebsocketMessage();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  public async getHint(): Promise<void> {
    if (this.data.ws) {
      const message: Message = { command: Command.GetHint };
      this.data.ws.sendMessage(JSON.stringify(message));
    }
  }

  private handleWebsocketMessage(): void {
    this.websocketMessageSub = this.data.ws.messages$.subscribe(resp => {
      if (resp.hint) {
        this.hints.push(resp.hint.text);
        this.isLastHint = resp.hint.is_last;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.isLoading = false;
        this.hasError = false;
      }

      if (
        resp.exercise?.validationTestsStatus == Status.Completed ||
        resp.exercise?.environmentStatus == Status.Canceled
      ) {
        this.isLoading = true;
        return;
      }

      if (resp.fatal_error) {
        this.hasError = true;
      }
    });
  }
}

export interface Hint {
  text: string
  is_last: boolean
}