import { Injectable } from '@angular/core';
import { Exercise, InterviewerExercisePlan, InviteLink, User, Company } from './exercise.module';
import { DynamoDBClient, BatchGetItemCommand, ScanCommand, PutItemCommand, QueryCommand, DeleteItemCommand, UpdateItemCommand, GetItemCommand, AttributeValue } from '@aws-sdk/client-dynamodb';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { Notification } from './dashboard/notification-widget/notification-widget.component';
import { StartKeyType } from './plans-management/plan-data-service/plan-data.service';
import { TaskStatus } from './status-parser';
import { ExerciseStatus } from './models/exercise-status.model';
import { AssignedPlan } from './models/assigned-plan.model';
import { UserPublicProfile } from './models/user-public-profile.model';
import { Ticket } from './models/ticket.model';
import { PaymentInformation } from './models/payment/payment-information.models';
import { ExerciseData } from './exercise/demo/models/exercise-data-interface';
import moment from 'moment-mini';

export enum DynamoDBTable {
  AssignedPlan = 'web-AssignedPlan',
  Users = 'web-Users',
  Exercise = 'web-Exercises',
  ExerciseStatus = 'web-ExerciseStatus',
  InterviewersExercisePlans = 'web-InterviewersExercisePlan',
  PaymentsInformation = 'web-PaymentInformation',
  Company = 'web-Company',
  InviteLink = 'web-InviteLink',
  CompanyMembers = 'web-CompanyMembers',
  CompanyConnections = 'web-CompanyConnections',
  CompanyNotifications = 'web-CompanyNotifications',
  UserNotifications = 'web-UserNotifications',
  SupportTickets = 'web-SupportTicket',
  DemoMode = 'web-DemoMode',
  DemoAssignedPlan = 'web-DemoAssignedPlan',
  DemoExerciseStatus = 'web-DemoExerciseStatus'
}

type DynamoDBMapAttribute = {
  [key: string]: AttributeValue
}

const TTL_TWO_WEEKS_SECONDS = 1209600;

@Injectable({
  providedIn: 'root'
})

export class DynamoDBService {

  client: DynamoDBClient;
  public isDemo!: Promise<boolean>;

  constructor(
    private authService: AuthService,
  ) {
    this.client = new DynamoDBClient({
      region: environment.dynamodb.region,
      endpoint: environment.dynamodb.endpoint,
      credentials: () => this.authService.getUserCredentials()
    });
  }

  private AttributeValueToMap<T extends string | number | Map<string, string>>(attr?: AttributeValue, exerciseIds?: string[]): Map<string, T> {
    const map = new Map<string, T>();
    if (attr !== undefined && attr.M !== undefined) {
      if (exerciseIds !== undefined) {
        exerciseIds.forEach(id => {
          const status = attr.M![id];
          if (status != undefined) {
            map.set(id, status.S! as T || status.N! as T);
          }
        });
        return map;
      }

      for (const key in attr.M) {
        const innerMap = new Map<string, string>();
        for (const innerKey in attr.M[key].M) {
          innerMap.set(innerKey, attr.M[key].M![innerKey].S!);
        }
        map.set(key, innerMap as T);
      }
      return map;
    }
    return map;
  }

  private DynamoDBMapToMap<T extends string | number>(dynamodbMap: DynamoDBMapAttribute): Map<string, T> {
    const tsMap = new Map<string, T>();
    for (const key in dynamodbMap) {
      if (dynamodbMap[key].S || dynamodbMap[key].N) {
        tsMap.set(key, dynamodbMap[key].S as T || dynamodbMap[key].N as T);
      }
    }
    return tsMap;
  }

  public async saveSupportTicket(email: string, id: string, date: string, subject: string, description: string, url: string, deviceInfo: string, message: string): Promise<void> {
    const putItemCommand = new PutItemCommand({
      TableName: DynamoDBTable.SupportTickets,
      Item: {
        email: { S: email },
        date_id: { S: `${date}#${id}` },
        id: { S: id },
        date: { S: date },
        subject: { S: subject },
        description: { S: description },
        deviceInfo: { S: deviceInfo },
        url: { S: url },
        fixedKey: { S: 'ALL' },
        conversation: { SS: [message] },
      }
    });
    await this.client.send(putItemCommand);
  }


  async getTicketCount(email: string): Promise<number> {
    const scanCommand = new QueryCommand({
      TableName: DynamoDBTable.SupportTickets,
      KeyConditionExpression: 'email = :hashKey',
      ExpressionAttributeValues: {
        ':hashKey': { S: email },
      },
      Select: 'COUNT'
    });
    const ticketCount = await this.client.send(scanCommand);
    return ticketCount.Count ?? 0;
  }

  async getTickets(email: string, limit: number, exclusiveStartKey?: string): Promise<{ tickets: Ticket[], lastEvaluatedKey?: string }> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.SupportTickets,
      KeyConditionExpression: 'email = :hashKey',
      ExpressionAttributeValues: {
        ':hashKey': { S: email },
      },
      ExpressionAttributeNames: {
        '#date': 'date',

      },
      Limit: limit,
      ExclusiveStartKey: exclusiveStartKey ? { 'email': { S: email }, 'date_id': { S: exclusiveStartKey } } : undefined,
      ProjectionExpression: 'email, subject, #date, date_id, id, description',
      ScanIndexForward: false
    });

    const response = await this.client.send(queryCommand);
    const tickets = (response.Items ?? []).map(item => {
      return new Ticket({
        email,
        id: item['id']?.S ?? '',
        date: item['date']?.S,
        subject: item['subject']?.S,
        description: item['description']?.S,
      });
    });

    let lastEvaluatedKey;
    if (response.LastEvaluatedKey) {
      lastEvaluatedKey = response.LastEvaluatedKey['date_id'].S;
    }
    return { tickets, lastEvaluatedKey };
  }

  async getAllTickets(limit: number, exclusiveStartKey?: string, exclusiveStartEmail?: string,): Promise<{ tickets: Ticket[], lastEvaluatedKey?: string, lastEvaluatedEmail?: string }> {
    const scanCommand = new QueryCommand({
      TableName: DynamoDBTable.SupportTickets,
      Limit: limit,
      IndexName: 'fixedKeyIndex',
      ProjectionExpression: 'email, subject, #date, id, description',
      KeyConditionExpression: 'fixedKey = :fixedKey',
      ScanIndexForward: false,
      ExpressionAttributeValues: {
        ':fixedKey': { S: 'ALL' },
      },
      ExpressionAttributeNames: {
        '#date': 'date',
      },
      ExclusiveStartKey: exclusiveStartKey && exclusiveStartEmail ? {
        'email': { S: exclusiveStartEmail },
        'date_id': { S: exclusiveStartKey }
      } : undefined,
    });

    const response = await this.client.send(scanCommand);
    const tickets = (response.Items ?? []).map(item => {
      return new Ticket({
        email: item['email']?.S ?? '',
        id: item['id']?.S ?? '',
        date: item['date']?.S,
        subject: item['subject']?.S,
        description: item['description']?.S,
      });
    });

    let lastEvaluatedKey: string | undefined;
    let lastEvaluatedEmail: string | undefined;
    if (response.LastEvaluatedKey) {
      lastEvaluatedKey = response.LastEvaluatedKey['date_id'].S;
      lastEvaluatedEmail = response.LastEvaluatedKey['email'].S;
    }
    return { tickets, lastEvaluatedKey, lastEvaluatedEmail };
  }

  async getTicketData(email: string, sortKey: string): Promise<Ticket> {
    const getCommand = new GetItemCommand({
      TableName: DynamoDBTable.SupportTickets,
      Key: {
        email: { S: email },
        date_id: { S: sortKey },
      },
      ExpressionAttributeNames: {
        '#date': 'date',
        '#url': 'url',
      },
      ProjectionExpression: 'id, #url, #date, subject, description, deviceInfo, conversation',
    });
    const response = await this.client.send(getCommand);
    if (!response.Item) {
      throw new Error('Ticket not found');
    }

    return new Ticket({
      email,
      sortKey,
      id: response.Item['id']?.S ?? '',
      url: response.Item['url']?.S,
      date: response.Item['date']?.S,
      subject: response.Item['subject']?.S,
      description: response.Item['description']?.S,
      deviceInfoJSON: response.Item['deviceInfo']?.S,
      conversationJSON: response.Item['conversation']?.SS
    });
  }

  public async saveNewTicketMessage(email: string, sortKey: string, jsonMessage: string): Promise<void> {
    await this.client.send(new UpdateItemCommand({
      TableName: DynamoDBTable.SupportTickets,
      Key: {
        email: { S: email },
        date_id: { S: sortKey }
      },
      UpdateExpression: 'ADD conversation :value',
      ExpressionAttributeValues: {
        ':value': { SS: [jsonMessage] },
      }
    }));
  }

  public async getAllExercises(): Promise<Array<Exercise>> {
    const scanCommand = new ScanCommand({
      TableName: DynamoDBTable.Exercise,
      ProjectionExpression: 'exerciseId, ExerciseName, ExerciseNamePretty, Description, Tags, Difficulty, ShortDescription, NeedTerminal, NeedCodeEditor, DynamicDescription, ExecutionTime, IsDemoModeAllowed'
    });
    const scanOutput = await this.client.send(scanCommand);
    return (scanOutput.Items ?? []).map(item => ({
      id: item['exerciseId'].S!,
      name: item['ExerciseName'].S!,
      userFriendlyName: item['ExerciseNamePretty'].S!,
      description: item['Description'].S!,
      tags: item['Tags'].L!.map(item => item.S!),
      difficulty: item['Difficulty'].S!,
      needTerminal: item['NeedTerminal']?.BOOL ?? false,
      needCodeEditor: item['NeedCodeEditor']?.BOOL ?? false,
      isDemoModeAllowed: item['IsDemoModeAllowed']?.BOOL ?? false,
      dynamicDescription: item['DynamicDescription']?.BOOL ?? false,
      executionTime: parseInt(item['ExecutionTime'].N!),
      shortDescription: item['ShortDescription'].S!,
      imageLink: `${environment.ExercisePicturePath}${item['exerciseId'].S!}.jpeg`,
      hints: [],
      environmentChartName: '',
      referenceSolutionChartName: '',
      validationTestsChartName: '',
      environmentChartVersion: '',
      referenceSolutionChartVersion: '',
      validationTestsChartVersion: '',
      shellType: '',
    }));
  }

  public async saveInterviewerExercisePlan(exercisesId: string[], exercisesName: string[], name: string, interviewerEmail: string, interviewerFullName: string, companyId: string, executionTime: number, date: string, planId: string, description: string, imageLink: string): Promise<void> {
    const putItemCommand = new PutItemCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      Item: {
        companyId: { S: companyId },
        planId: { S: planId },
        ExerciseID: { SS: exercisesId },
        ExerciseName: { SS: exercisesName },
        PlanName: { S: name },
        InterviewerEmail: { S: interviewerEmail },
        InterviewerFullName: { S: interviewerFullName },
        LastModificationTime: { S: date },
        CreationTime: { S: date },
        MaxExecutionTime: { N: executionTime.toString() },
        Description: { S: description },
        ImageLink: { S: imageLink },
      },
    });
    await this.client.send(putItemCommand);
  }

  public async updateInterviewerExercisePlan(companyId: string, planId: string, lastModificationTime: string, planName: string, planDescription: string, exerciseId: string[], exerciseNames: string[], executionTime: string): Promise<void> {
    const updateCommand = new UpdateItemCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      Key: {
        companyId: { S: companyId },
        planId: { S: planId }
      },
      UpdateExpression: 'set ExerciseID = :eid, LastModificationTime = :lmt, ExerciseName = :en, PlanName = :pn, MaxExecutionTime = :met, Description = :pd',
      ExpressionAttributeValues: {
        ':pn': { S: planName },
        ':eid': { SS: exerciseId },
        ':lmt': { S: lastModificationTime },
        ':en': { SS: exerciseNames },
        ':met': { N: executionTime },
        ':pd': { S: planDescription }
      }
    });
    await this.client.send(updateCommand);
  }

  public async getInterviewerExercisePlansForDashboard(companyId: string): Promise<Array<InterviewerExercisePlan>> {
    const command = new QueryCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      KeyConditionExpression: 'companyId = :c',
      ProjectionExpression: 'planId, PlanName, Description',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
    });

    try {
      const data = await this.client.send(command);
      if (!data.Items) {
        return [];
      }

      return (data.Items ?? []).map(item => ({
        id: item['planId'].S!,
        name: item['PlanName'].S!,
        description: item['Description'].S!,
        exerciseIDs: [],
        exerciseNames: [],
        creationTime: '',
        interviewerEmail: '',
        interviewerFullName: '',
        lastModificationTime: '',
        executionTime: 0,
        companyId,
      }));
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async getInterviewerExercisePlans(companyId: string, limit: number, startKey?: StartKeyType): Promise<[Array<InterviewerExercisePlan>, StartKeyType]> {
    if (startKey === null) {
      startKey = undefined;
    }

    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      Limit: limit,
      KeyConditionExpression: 'companyId = :c',
      ExclusiveStartKey: startKey,
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
    });
    const queryOutput = await this.client.send(queryCommand);
    return [(queryOutput.Items ?? []).map(item => ({
      id: item['planId'].S!,
      exerciseIDs: item['ExerciseID'].SS!,
      name: item['PlanName'].S!,
      description: item['Description'].S!,
      exerciseNames: item['ExerciseName'].SS!,
      interviewerEmail: item['InterviewerEmail'].S!,
      interviewerFullName: item['InterviewerFullName'].S!,
      lastModificationTime: item['LastModificationTime'].S!,
      creationTime: item['CreationTime'].S!,
      imageLink: item['ImageLink']?.S || environment.DefaultPlanPicture,
      executionTime: parseInt(item['MaxExecutionTime'].N!),
      companyId,
    })), queryOutput.LastEvaluatedKey];
  }

  public async getInterviewerExercisePlansForWidget(companyId: string, limit: number): Promise<Array<InterviewerExercisePlan>> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      Limit: limit,
      KeyConditionExpression: 'companyId = :c',
      ProjectionExpression: 'planId, PlanName, Description, ImageLink',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
    });
    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => ({
      id: item['planId'].S!,
      name: item['PlanName'].S!,
      description: item['Description'].S!,
      imageLink: item['ImageLink']?.S || environment.DefaultPlanPicture,
      exerciseIDs: [],
      exerciseNames: [],
      interviewerEmail: '',
      interviewerFullName: '',
      lastModificationTime: '',
      creationTime: '',
      executionTime: 0,
      companyId,
    }));
  }

  public async deleteInterviewerExercisePlans(companyId: string, planId: string): Promise<void> {
    const deleteItemCommand = new DeleteItemCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      Key: {
        companyId: { S: companyId },
        planId: { S: planId }
      }
    });
    await this.client.send(deleteItemCommand);
  }

  public async getUserData(email: string): Promise<User> {
    const getCommand = new GetItemCommand({
      TableName: DynamoDBTable.Users,
      ProjectionExpression: 'FirstName, LastName, PhoneNumber',
      Key: {
        email: { S: email }
      },
    });
    const output = await this.client.send(getCommand);
    if (!output.Item) {
      return {
        firstName: '',
        lastName: '',
        phoneNumber: '',
      };
    }

    return {
      firstName: output.Item['FirstName']?.S ?? '',
      lastName: output.Item['LastName']?.S ?? '',
      phoneNumber: output.Item['PhoneNumber']?.S ?? '',
    };
  }

  public async getAssignedPlans(companyId: string): Promise<Array<AssignedPlan>> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      IndexName: 'DateIndex',
      KeyConditionExpression: 'InterviewerCompanyID = :pk',
      FilterExpression: 'IsCanceled <> :true',
      ProjectionExpression: 'AssignedPlanID, PlanID, PlanName, InterviewerFullName, InterviewerEmail, UserFullName, UserEmail, Deadline, AssignedDate, PricePerSecond, IsPaid, ExerciseStatuses, IsRejected, IsFinished',
      ExpressionAttributeValues: {
        ':pk': { S: companyId },
        ':true': { BOOL: true },
      },
      ScanIndexForward: false,
    });
    const output = await this.client.send(queryCommand);
    return (output.Items ?? []).map(item => {
      return new AssignedPlan({
        interviewerCompanyID: companyId,
        id: item['AssignedPlanID'].S!,
        userEmail: item['UserEmail'].S!,
        userFullName: item['UserFullName'].S,
        planID: item['PlanID'].S,
        planName: item['PlanName'].S,
        interviewerFullName: item['InterviewerFullName'].S,
        interviewerEmail: item['InterviewerEmail'].S,
        deadline: item['Deadline'].S,
        assignedDate: item['AssignedDate'].S,
        isPaid: item['IsPaid']?.BOOL,
        isRejected: item['IsRejected']?.BOOL,
        isFinished: item['IsFinished']?.BOOL ?? false,
        pricePerSecond: parseFloat(item['PricePerSecond'].N!) ?? 0,
        exerciseStatusesMap: this.DynamoDBMapToMap<string>(item['ExerciseStatuses'].M!),
      });
    });
  }

  public async getAssignedPlansCount(companyId: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      KeyConditionExpression: 'InterviewerCompanyID = :c',
      FilterExpression: 'IsCanceled <> :true',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
        ':true': { BOOL: true }
      },
      Select: 'COUNT'
    });
    const queryOutput = await this.client.send(queryCommand);
    return queryOutput.Count ?? 0;
  }

  public async getAssignedPlansByDate(companyId: string, limit: number): Promise<Array<AssignedPlan>> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      IndexName: 'DateIndex',
      KeyConditionExpression: 'InterviewerCompanyID = :ci',
      FilterExpression: 'IsCanceled <> :true',
      ProjectionExpression: 'AssignedPlanID, UserEmail, PlanID, PlanName, AssignedDate, ExerciseStatuses, IsFinished, IsRejected, IsCanceled',
      ScanIndexForward: false,
      Limit: limit,
      ExpressionAttributeValues: {
        ':ci': { S: companyId },
        ':true': { BOOL: true }
      },
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new AssignedPlan({
        interviewerCompanyID: companyId,
        id: item['AssignedPlanID'].S!,
        userEmail: item['UserEmail'].S!,
        assignedDate: item['AssignedDate'].S,
        planID: item['PlanID'].S!,
        planName: item['PlanName'].S!,
        isFinished: item['IsFinished']?.BOOL,
        isRejected: item['IsRejected']?.BOOL,
        isCanceled: item['IsCanceled']?.BOOL,
        exerciseStatusesMap: this.DynamoDBMapToMap<string>(item['ExerciseStatuses'].M!)
      });
    });
  }

  public async getAssignedPlansByDeadline(companyId: string, startOfMonth: string | undefined, endOfMonth: string | undefined): Promise<Array<AssignedPlan>> {
    if (!startOfMonth) {
      startOfMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    }

    if (!endOfMonth) {
      endOfMonth = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    }

    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      KeyConditionExpression: 'InterviewerCompanyID = :ci AND Deadline BETWEEN :start AND :end',
      FilterExpression: 'IsCanceled <> :true',
      ProjectionExpression: 'AssignedPlanID, UserEmail, Deadline, PlanID, PlanName, UserFullName',
      ExpressionAttributeValues: {
        ':ci': { S: companyId },
        ':start': { S: startOfMonth },
        ':end': { S: endOfMonth },
        ':true': { BOOL: true }
      },
      IndexName: 'DeadlineIndex',
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new AssignedPlan({
        interviewerCompanyID: companyId,
        id: item['AssignedPlanID'].S!,
        userEmail: item['UserEmail'].S!,
        deadline: item['Deadline'].S,
        planID: item['PlanID'].S!,
        planName: item['PlanName'].S!,
        userFullName: item['UserFullName'].S!
      });
    });
  }

  public async getCompanyConnectionsCount(companyId: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.CompanyConnections,
      KeyConditionExpression: 'companyId = :c',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
      Select: 'COUNT'
    });
    const queryOutput = await this.client.send(queryCommand);
    return queryOutput.Count ?? 0;
  }

  public async getCompanyMembersCount(companyId: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.CompanyMembers,
      KeyConditionExpression: 'companyId = :c',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
      Select: 'COUNT'
    });
    const queryOutput = await this.client.send(queryCommand);
    return queryOutput.Count ?? 0;
  }

  public async getCompanyPlansCount(companyId: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.InterviewersExercisePlans,
      KeyConditionExpression: 'companyId = :c',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
      Select: 'COUNT'
    });
    const queryOutput = await this.client.send(queryCommand);
    return queryOutput.Count ?? 0;
  }

  public async getUsersAndCompanyMembers(companyId: string, emails: string[]): Promise<Array<UserPublicProfile>> {
    if (emails.length === 0) {
      return [];
    }

    const keys: { [key: string]: AttributeValue }[] = emails.map(email => ({
      'companyId': { S: companyId },
      'email': { S: email }
    }));

    const command = new BatchGetItemCommand({
      RequestItems: {
        [DynamoDBTable.CompanyConnections]: {
          Keys: keys,
          ProjectionExpression: 'email, PictureLink, FirstName, LastName'
        },
        [DynamoDBTable.CompanyMembers]: {
          Keys: keys,
          ProjectionExpression: 'email, PictureLink, FirstName, LastName'
        }
      }
    });

    const data = await this.client.send(command);
    if (!data.Responses) {
      return [];
    }

    const users = (data.Responses[DynamoDBTable.CompanyConnections] ?? []).map(item => {
      return new UserPublicProfile({
        email: item['email'].S!,
        firstName: item['FirstName']?.S,
        lastName: item['LastName']?.S,
        picture: item['PictureLink']?.S,
        role: 'user'
      });
    });

    users.push(...(data.Responses[DynamoDBTable.CompanyMembers] ?? []).map(item => {
      return new UserPublicProfile({
        email: item['email'].S!,
        firstName: item['FirstName']?.S,
        lastName: item['LastName']?.S,
        picture: item['PictureLink']?.S,
        role: 'interviewer'
      });
    }));

    return users;
  }

  public async getInterviewerExerciseStatuses(assignedPlanID: string, userEmail: string, companyId: string): Promise<Array<ExerciseStatus>> {
    const queryOutput = await this.client.send(new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      KeyConditionExpression: 'InterviewerCompanyID_AssignedPlanID_UserEmail = :cpu',
      ProjectionExpression: 'CompletionTime, StartTime, ExerciseName, #status, SourceIps, ValidationCount, UsedHintsCount, RemainingTime, MaxExecutionTime, ExerciseID',
      ExpressionAttributeValues: {
        ':cpu': { S: `${companyId}#${assignedPlanID}#${userEmail}` },
      },
      ExpressionAttributeNames: {
        '#status': 'Status'
      },
    }));

    return (queryOutput.Items ?? []).map(item => {
      return new ExerciseStatus({
        assignedPlanID,
        userEmail,
        exerciseID: item['ExerciseID'].S!,
        completionTime: item['CompletionTime']?.S,
        startTime: item['StartTime']?.S,
        exerciseName: item['ExerciseName']?.S,
        status: item['Status']?.S,
        sourceIPs: item['SourceIps']?.SS ?? [],
        validationCount: parseInt(item['ValidationCount']?.N ?? '0') ?? 0,
        usedHintsCount: parseInt(item['UsedHintsCount']?.N ?? '0') ?? 0,
        remainingTime: parseInt(item['RemainingTime']?.N ?? '0') ?? 0,
        maxExecutionTime: parseInt(item['MaxExecutionTime']?.N ?? '0') ?? 0,
      });
    });
  }

  public async getInterviewerExerciseStatus(assignedPlanID: string, userEmail: string, companyId: string, exerciseID: string): Promise<ExerciseStatus> {
    const output = await this.client.send(new GetItemCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      ProjectionExpression: 'CompletionTime, StartTime, ExerciseName, #status, SourceIps, ValidationCount, UsedHintsCount, RemainingTime, MaxExecutionTime, DynamicDescription',
      Key: {
        InterviewerCompanyID_AssignedPlanID_UserEmail: { S: `${companyId}#${assignedPlanID}#${userEmail}` },
        ExerciseID: { S: exerciseID },
      },
      ExpressionAttributeNames: {
        '#status': 'Status'
      },
    }));

    if (!output.Item) {
      throw new Error('Exercise status not found');
    }

    return new ExerciseStatus({
      assignedPlanID,
      userEmail,
      exerciseID,
      dynamicDescription: output.Item['DynamicDescription']?.S,
      completionTime: output.Item['CompletionTime']?.S,
      startTime: output.Item['StartTime']?.S,
      exerciseName: output.Item['ExerciseName']?.S,
      status: output.Item['Status']?.S,
      sourceIPs: output.Item['SourceIps']?.SS,
      validationCount: parseInt(output.Item['ValidationCount']?.N ?? '0'),
      usedHintsCount: parseInt(output.Item['UsedHintsCount']?.N ?? '0'),
      remainingTime: parseInt(output.Item['RemainingTime']?.N ?? '0'),
      maxExecutionTime: parseInt(output.Item['MaxExecutionTime']?.N ?? '0'),
    });
  }

  public async putInviteLink(inviteId: string, companyName: string, companyId: string): Promise<void> {
    const putItemCommand = new PutItemCommand({
      TableName: DynamoDBTable.InviteLink,
      Item: {
        companyId: { S: companyId },
        inviteId: { S: inviteId },
        CompanyName: { S: companyName },
        TTL: { N: (Math.floor(Date.now() / 1000) + TTL_TWO_WEEKS_SECONDS).toString() },
      },
    });
    await this.client.send(putItemCommand);
  }

  public async getInviteLinks(companyId: string): Promise<Array<InviteLink>> {
    const scanCommand = new QueryCommand({
      TableName: DynamoDBTable.InviteLink,
      KeyConditionExpression: 'companyId = :c',
      ProjectionExpression: 'inviteId',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
      },
    });
    const queryOutput = await this.client.send(scanCommand);
    return (queryOutput.Items ?? []).map(item => ({
      companyId: companyId,
      id: item['inviteId'].S!,
    }));
  }

  public async getPaymentsInformation(companyId: string, userEmail_assignedPlanIds: string[]): Promise<Array<PaymentInformation> | undefined> {
    if (userEmail_assignedPlanIds.length == 0) {
      return;
    }

    const keys: { [key: string]: AttributeValue }[] = userEmail_assignedPlanIds.map(userEmail_assignedPlanId => ({
      'companyId': { S: companyId },
      'userEmail_assignedPlanId': { S: userEmail_assignedPlanId }
    }));

    const command = new BatchGetItemCommand({
      RequestItems: {
        [DynamoDBTable.PaymentsInformation]: {
          Keys: keys,
        },
      }
    });

    try {
      const data = await this.client.send(command);
      if (!data.Responses) {
        return undefined;
      }

      return (data.Responses[DynamoDBTable.PaymentsInformation] ?? []).map(item => new PaymentInformation({
        assignedPlanId: item['AssignedPlanId'].S!,
        userEmail: item['Email'].S!,
        pricePerSecond: parseFloat(item['PricePerSecond'].N!),
        paymentReferences: this.AttributeValueToMap<Map<string, string>>(item['PaymentReferences']),
        paymentNotNeeded: item['PaymentNotNeeded']?.BOOL ?? false,
        amount: parseFloat(item['Amount']?.N ?? '0'),
        interviewerFullName: item['InterviewerFullName'].S!,
        planName: item['PlanName'].S!,
        lastPaymentDate: item['LastPaymentDate']?.S ?? undefined
      }));
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  public async getPaymentInformation(companyId: string, userEmail: string, assignedPlanId: string): Promise<PaymentInformation | undefined> {
    const getItemCommand = new GetItemCommand({
      TableName: DynamoDBTable.PaymentsInformation,
      Key: {
        companyId: { S: companyId },
        userEmail_assignedPlanId: { S: `${userEmail}#${assignedPlanId}` }
      }
    });

    try {
      const getItemOutput = await this.client.send(getItemCommand);
      if (!getItemOutput.Item) {
        return undefined;
      }
      return new PaymentInformation({
        userEmail: userEmail,
        assignedPlanId: assignedPlanId,
        pricePerSecond: parseFloat(getItemOutput.Item['PricePerSecond'].N!),
        paymentReferences: this.AttributeValueToMap<Map<string, string>>(getItemOutput.Item['PaymentReferences']),
        paymentNotNeeded: getItemOutput.Item['PaymentNotNeeded']?.BOOL ?? false,
        amount: parseFloat(getItemOutput.Item['Amount']?.N ?? '0'),
        interviewerFullName: getItemOutput.Item['InterviewerFullName'].S!,
        planName: getItemOutput.Item['PlanName'].S!,
        lastPaymentDate: getItemOutput.Item['LastPaymentDate']?.S ?? undefined,
      });
    } catch (err) {
      throw new Error('Failed to load payment information');
    }
  }

  public async getUserPaymentInformation(userEmail: string, assignedPlanId: string): Promise<PaymentInformation | null> {
    const getItemCommand = new QueryCommand({
      TableName: DynamoDBTable.PaymentsInformation,
      IndexName: 'UserIndex',
      KeyConditionExpression: 'userEmail_assignedPlanId = :pk',
      Limit: 1,
      ExpressionAttributeValues: {
        ':pk': { S: `${userEmail}#${assignedPlanId}` }
      }
    });

    const result = await this.client.send(getItemCommand);
    if (!result.Items || result.Items.length === 0) {
      return null;
    }

    return new PaymentInformation({
      userEmail: userEmail,
      assignedPlanId: assignedPlanId,
      pricePerSecond: parseFloat(result.Items[0]['PricePerSecond'].N!),
      paymentReferences: this.AttributeValueToMap<Map<string, string>>(result.Items[0]['PaymentReferences']),
      paymentNotNeeded: result.Items[0]['PaymentNotNeeded']?.BOOL ?? false,
      amount: parseFloat(result.Items[0]['Amount']?.N ?? '0'),
      interviewerFullName: result.Items[0]['InterviewerFullName'].S!,
      planName: result.Items[0]['PlanName'].S!,
      lastPaymentDate: result.Items[0]['LastPaymentDate']?.S ?? undefined,
    });
  }

  public async getCompanyPaymentTotalItemsCount(companyId: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.PaymentsInformation,
      KeyConditionExpression: 'companyId = :c',
      FilterExpression: 'attribute_exists(PaymentNotNeeded) OR size(PaymentReferences) > :zero ',
      ExpressionAttributeValues: {
        ':c': { S: companyId },
        ':zero': { N: '0' }
      },
      Select: 'COUNT',
    });
    const output = await this.client.send(queryCommand);
    return output.Count ?? 0;
  }

  public async getCompanyPaymentInformation(companyId: string, limit: number, exclusiveStartKey?: { [key: string]: AttributeValue }): Promise<[Array<PaymentInformation> | undefined, { [key: string]: AttributeValue } | undefined]> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.PaymentsInformation,
      IndexName: 'DateIndex',
      KeyConditionExpression: 'companyId = :c',
      FilterExpression: 'attribute_exists(PaymentNotNeeded) OR size(PaymentReferences) > :zero ',
      Limit: limit,
      ExclusiveStartKey: exclusiveStartKey,
      ScanIndexForward: false,
      ExpressionAttributeValues: {
        ':c': { S: companyId },
        ':zero': { N: '0' }
      },
    });
    const queryOutput = await this.client.send(queryCommand);
    if (!queryOutput || !queryOutput.Items) {
      return [undefined, undefined];
    }

    return [(queryOutput.Items ?? []).map(item => new PaymentInformation({
      userEmail: item['Email'].S!,
      assignedPlanId: item['AssignedPlanId'].S!,
      pricePerSecond: parseFloat(item['PricePerSecond'].N!),
      paymentReferences: this.AttributeValueToMap<Map<string, string>>(item['PaymentReferences']),
      paymentNotNeeded: item['PaymentNotNeeded']?.BOOL ?? false,
      amount: parseFloat(item['Amount']?.N ?? '0'),
      interviewerFullName: item['InterviewerFullName'].S!,
      planName: item['PlanName'].S!,
      lastPaymentDate: item['LastPaymentDate']?.S ?? undefined,
    })), queryOutput.LastEvaluatedKey];
  }

  public async getUserPaymentItemsCount(email: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.PaymentsInformation,
      KeyConditionExpression: 'email_paymentType = :email',
      IndexName: 'EmailIndex',
      FilterExpression: 'attribute_exists(PaymentNotNeeded) OR size(PaymentReferences) > :zero ',
      ExpressionAttributeValues: {
        ':email': { S: `${email}#Personal` },
        ':zero': { N: '0' }
      },
      Select: 'COUNT',
    });
    const output = await this.client.send(queryCommand);
    return output.Count ?? 0;
  }

  public async getUserPaymentsInformation(email: string, limit: number, exclusiveStartKey?: { [key: string]: AttributeValue }): Promise<[Array<PaymentInformation> | undefined, { [key: string]: AttributeValue } | undefined]> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.PaymentsInformation,
      IndexName: 'EmailIndex',
      KeyConditionExpression: 'email_paymentType = :email',
      FilterExpression: 'attribute_exists(PaymentNotNeeded) OR size(PaymentReferences) > :zero ',
      Limit: limit,
      ExclusiveStartKey: exclusiveStartKey,
      ScanIndexForward: false,
      ExpressionAttributeValues: {
        ':email': { S: `${email}#Personal` },
        ':zero': { N: '0' }
      },
    });

    const queryOutput = await this.client.send(queryCommand);
    if (!queryOutput || !queryOutput.Items) {
      return [undefined, undefined];
    }

    return [(queryOutput.Items ?? []).map(item => new PaymentInformation({
      userEmail: item['Email'].S!,
      assignedPlanId: item['AssignedPlanId'].S!,
      paymentReferences: this.AttributeValueToMap<Map<string, string>>(item['PaymentReferences']),
      paymentNotNeeded: item['PaymentNotNeeded']?.BOOL ?? false,
      amount: parseFloat(item['Amount']?.N ?? '0'),
      planName: item['PlanName'].S!,
      lastPaymentDate: item['LastPaymentDate']?.S ?? undefined,
    })), queryOutput.LastEvaluatedKey];
  }

  public async getCompanyVatNumber(companyId: string): Promise<Company | undefined> {
    const getCommand = new GetItemCommand({
      TableName: DynamoDBTable.Company,
      Key: {
        'id': { S: companyId }
      },
      ProjectionExpression: 'VATNumber, IsVATValid'
    });
    const output = await this.client.send(getCommand);
    if (!output.Item) {
      return undefined;
    }

    return {
      id: companyId,
      name: output.Item['Name']?.S ?? '',
      registrationNumber: output.Item['RegistrationNumber']?.S ?? '',
      vatNumber: output.Item['VATNumber'].S!,
      phoneNumber: output.Item['PhoneNumber']?.S ?? '',
      email: output.Item['Email']?.S ?? '',
      countryCode: output.Item['CountryCode']?.S ?? '',
      state: output.Item['State']?.S ?? '',
      city: output.Item['City']?.S ?? '',
      street: output.Item['Street']?.S ?? '',
      postCode: output.Item['Postcode']?.S ?? '',
      isVatValid: output.Item['IsVATValid']?.BOOL ?? false,
      trialEndDate: output.Item['TrialEndDate']?.S ?? '',
    };
  }

  public async getCompanyData(companyId: string): Promise<Company> {
    const getCommand = new GetItemCommand({
      TableName: DynamoDBTable.Company,
      Key: {
        'id': { S: companyId }
      },
      ExpressionAttributeNames: {
        '#name': 'Name',
        '#state': 'State'
      },
      ProjectionExpression: '#name, RegistrationNumber, VATNumber, PhoneNumber, CountryCode, #state, City, Street, Postcode, Email, IsVATValid, TrialEndDate'
    });
    const output = await this.client.send(getCommand);
    if (!output.Item) {
      throw new Error('Company data not found! Please try again later');
    }

    return {
      id: companyId,
      name: output.Item['Name'].S!,
      registrationNumber: output.Item['RegistrationNumber'].S!,
      vatNumber: output.Item['VATNumber'].S!,
      phoneNumber: output.Item['PhoneNumber'].S!,
      email: output.Item['Email'].S!,
      countryCode: output.Item['CountryCode'].S!,
      state: output.Item['State'].S!,
      city: output.Item['City'].S!,
      street: output.Item['Street'].S!,
      postCode: output.Item['Postcode'].S!,
      isVatValid: output.Item['IsVATValid']?.BOOL ?? false,
      trialEndDate: output.Item['TrialEndDate'].S!,
    };
  }

  public async getUserExercisesPlansForDashboard(
    email: string,
    startOfMonth: string = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    endOfMonth: string = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')
  ): Promise<AssignedPlan[]> {

    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      KeyConditionExpression: 'UserEmail = :e AND Deadline BETWEEN :start AND :end',
      FilterExpression: 'IsRejected <> :true AND IsCanceled <> :true',
      ProjectionExpression: 'AssignedPlanID, InterviewerFullName, InterviewerEmail, Deadline, PlanName, InterviewerCompanyName',
      IndexName: 'UserDeadlineIndex',
      ExpressionAttributeValues: {
        ':e': { S: email },
        ':start': { S: startOfMonth },
        ':end': { S: endOfMonth },
        ':true': { BOOL: true }
      },
    });
    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new AssignedPlan({
        userEmail: email,
        interviewerCompanyID: '',
        id: item['AssignedPlanID'].S!,
        deadline: item['Deadline'].S!,
        planName: item['PlanName'].S!,
        interviewerEmail: item['InterviewerEmail'].S!,
        interviewerFullName: item['InterviewerFullName'].S!,
        interviewerCompanyName: item['InterviewerCompanyName'].S!,
      });
    });
  }

  public async isWelcomePlan(email: string, assignedPlanId: string): Promise<boolean> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      IndexName: 'UserIndex',
      KeyConditionExpression: 'UserEmail = :pk AND AssignedPlanID = :sk',
      ProjectionExpression: 'IsWelcomePlan',
      Limit: 1,
      ExpressionAttributeValues: {
        ':pk': { S: email },
        ':sk': { S: assignedPlanId }
      },
    });
    const output = await this.client.send(queryCommand);
    if (!output.Items || output.Items.length === 0) {
      throw Error('Exercise plan not found');
    }
    return output.Items[0]['IsWelcomePlan'].BOOL ?? false;
  }

  public async getUserExercisePlan(email: string, assignedPlanId: string): Promise<AssignedPlan> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      IndexName: 'UserIndex',
      KeyConditionExpression: 'UserEmail = :pk AND AssignedPlanID = :sk',
      ProjectionExpression: 'InterviewerFullName, InterviewerEmail, Deadline, PlanName, PlanDescription, InterviewerCompanyName, ExerciseStatuses, IsWelcomePlan, ExerciseExecutionTime, PricePerSecond, InterviewerCompanyID, IsRejected, IsFinished',
      Limit: 1,
      ExpressionAttributeValues: {
        ':pk': { S: email },
        ':sk': { S: assignedPlanId }
      },
    });
    const output = await this.client.send(queryCommand);
    if (!output.Items || output.Items.length === 0) {
      throw new Error('Failed to fetch user exercise plan');
    }

    return new AssignedPlan({
      userEmail: email,
      id: assignedPlanId,
      interviewerFullName: output.Items[0]['InterviewerFullName'].S!,
      interviewerEmail: output.Items[0]['InterviewerEmail'].S!,
      interviewerCompanyName: output.Items[0]['InterviewerCompanyName'].S!,
      interviewerCompanyID: output.Items[0]['InterviewerCompanyID'].S!,
      deadline: output.Items[0]['Deadline'].S!,
      planName: output.Items[0]['PlanName'].S!,
      planDescription: output.Items[0]['PlanDescription'].S!,
      pricePerSecond: parseFloat(output.Items[0]['PricePerSecond']?.N ?? '0'),
      exerciseStatusesMap: this.DynamoDBMapToMap<string>(output.Items[0]['ExerciseStatuses'].M!),
      exerciseExecutionTime: this.DynamoDBMapToMap<number>(output.Items[0]['ExerciseExecutionTime'].M!),
      isWelcomePlan: output.Items[0]['IsWelcomePlan']?.BOOL,
      isFinished: output.Items[0]['IsFinished']?.BOOL,
      isRejected: output.Items[0]['IsRejected']?.BOOL,
    });
  }

  public async getUserExercisesPlans(email: string): Promise<Array<AssignedPlan>> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      KeyConditionExpression: 'UserEmail = :e',
      FilterExpression: 'IsRejected <> :true',
      IndexName: 'UserIndex',
      ProjectionExpression: 'AssignedPlanID, InterviewerFullName, InterviewerEmail, InterviewerCompanyID, Deadline, PlanName, PlanDescription, InterviewerCompanyName, ExerciseStatuses, IsCanceled, IsWelcomePlan, IsFinished, IsPaid',
      ExpressionAttributeValues: {
        ':e': { S: email },
        ':true': { BOOL: true }
      },
    });
    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new AssignedPlan({
        userEmail: email,
        interviewerCompanyID: item['InterviewerCompanyID'].S!,
        id: item['AssignedPlanID'].S!,
        interviewerFullName: item['InterviewerFullName'].S!,
        interviewerEmail: item['InterviewerEmail'].S!,
        deadline: item['Deadline'].S!,
        planName: item['PlanName'].S!,
        planDescription: item['PlanDescription'].S!,
        isCanceled: item['IsCanceled']?.BOOL,
        isFinished: item['IsFinished']?.BOOL ?? false,
        interviewerCompanyName: item['InterviewerCompanyName'].S!,
        exerciseStatusesMap: this.DynamoDBMapToMap<string>(item['ExerciseStatuses'].M!),
        isPaid: item['IsPaid']?.BOOL ?? false,
        isWelcomePlan: item['IsWelcomePlan']?.BOOL ?? false
      });
    });
  }

  public async getUserExercisesPlansForWidget(email: string, limit: number): Promise<Array<AssignedPlan>> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      IndexName: 'UserIndex',
      KeyConditionExpression: 'UserEmail = :e',
      FilterExpression: 'IsRejected <> :true',
      ProjectionExpression: 'AssignedPlanID, PlanName, PlanDescription, Deadline',
      Limit: limit,
      ExpressionAttributeValues: {
        ':e': { S: email },
        ':true': { BOOL: true }
      },
      ScanIndexForward: true
    });
    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new AssignedPlan({
        userEmail: email,
        interviewerCompanyID: '',
        id: item['AssignedPlanID'].S!,
        planName: item['PlanName'].S!,
        planDescription: item['PlanDescription'].S!,
        deadline: item['Deadline'].S,
      });
    });
  }

  public async getCompanyMember(companyId: string, email: string): Promise<UserPublicProfile> {
    const getCommand = new GetItemCommand({
      TableName: DynamoDBTable.CompanyMembers,
      ProjectionExpression: 'FirstName, LastName, PictureLink, CountryCode, PhoneNumber',
      Key: {
        companyId: { S: companyId },
        email: { S: email }
      }
    });

    const output = await this.client.send(getCommand);
    if (!output.Item) {
      throw new Error('Member not found');
    }

    return new UserPublicProfile({
      email,
      firstName: output.Item['FirstName'].S,
      lastName: output.Item['LastName'].S,
      picture: output.Item['PictureLink'].S,
      countryCode: output.Item['CountryCode'].S,
      phoneNumber: output.Item['PhoneNumber'].S
    });
  }

  public async getCompanyMembers(companyId: string): Promise<Array<UserPublicProfile>> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.CompanyMembers,
      KeyConditionExpression: 'companyId = :ci',
      ExpressionAttributeValues: {
        ':ci': { S: companyId },
      }
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new UserPublicProfile({
        email: item['email'].S!,
        firstName: item['FirstName']?.S,
        lastName: item['LastName']?.S,
        picture: item['PictureLink']?.S,
        countryCode: item['CountryCode']?.S,
        phoneNumber: item['PhoneNumber']?.S,
        role: 'Member'
      });
    });
  }

  public async deleteInvite(inviteId: string, companyId: string): Promise<void> {
    await this.client.send(new DeleteItemCommand({
      TableName: DynamoDBTable.InviteLink,
      Key: {
        'companyId': { S: companyId },
        'inviteId': { S: inviteId }
      },
    }));
  }

  public async getMembersInvites(companyId: string): Promise<Array<UserPublicProfile>> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.InviteLink,
      KeyConditionExpression: 'companyId = :ci',
      ProjectionExpression: 'inviteId, Email',
      FilterExpression: 'InviteType <> :type',
      ExpressionAttributeValues: {
        ':ci': { S: companyId },
        ':type': { S: 'user' }
      },
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new UserPublicProfile({
        email: item['Email'].S!,
        inviteId: item['inviteId'].S,
      });
    });
  }

  public async getUserExerciseStatusCount(email: string, firstStatus: TaskStatus, secondStatus?: TaskStatus): Promise<number> {
    const expressionAttributeValues: Record<string, AttributeValue> = {
      ':email': { S: email },
      ':firstStatus': { S: firstStatus },
      ':true': { BOOL: true },
    };
    let keyConditionExpression = '#Status = :firstStatus AND UserEmail = :email';

    if (secondStatus) {
      expressionAttributeValues[':secondStatus'] = { S: secondStatus };
      keyConditionExpression = '#Status BETWEEN :firstStatus AND :secondStatus AND UserEmail = :email';
    }

    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      KeyConditionExpression: keyConditionExpression,
      Select: 'COUNT',
      IndexName: 'EmailIndex',
      FilterExpression: 'IsRejected <> :true AND IsCanceled <> :true AND IsPlanFinished <> :true',
      ExpressionAttributeValues: expressionAttributeValues,
      ExpressionAttributeNames: {
        '#Status': 'Status',
      },
    });

    const output = await this.client.send(queryCommand);
    return output.Count ?? 0;
  }

  public async getUserExercisePlansCount(email: string): Promise<number> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      IndexName: 'UserIndex',
      KeyConditionExpression: 'UserEmail = :e',
      FilterExpression: 'IsRejected <> :true',
      Select: 'COUNT',
      ExpressionAttributeValues: {
        ':e': { S: email },
        ':true': { BOOL: true }
      },
    });
    const output = await this.client.send(queryCommand);
    return output.Count ?? 0;
  }

  public async getUserExerciseByStatus(email: string, limit: number, firstStatus: TaskStatus, secondStatus?: TaskStatus): Promise<ExerciseStatus[]> {
    const expressionAttributeValues: Record<string, AttributeValue> = {
      ':email': { S: email },
      ':firstStatus': { S: firstStatus },
      ':true': { BOOL: true }
    };
    let keyConditionExpression = '#Status = :firstStatus AND UserEmail = :email';

    if (secondStatus) {
      expressionAttributeValues[':secondStatus'] = { S: secondStatus };
      keyConditionExpression = '#Status BETWEEN :firstStatus AND :secondStatus AND UserEmail = :email';
    }

    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      KeyConditionExpression: keyConditionExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      Limit: limit,
      ScanIndexForward: true,
      FilterExpression: 'IsRejected <> :true AND IsCanceled <> :true AND IsPlanFinished <> :true',
      IndexName: 'EmailIndex',
      ProjectionExpression: 'AssignedPlanID, ExerciseID, UrlID, ExerciseName, #Status, PlanName, IsRejected, IsCanceled',
      ExpressionAttributeNames: {
        '#Status': 'Status',
      },
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new ExerciseStatus({
        userEmail: email,
        assignedPlanID: item['AssignedPlanID'].S!,
        exerciseID: item['ExerciseID'].S!,
        urlID: item['UrlID'].S!,
        exerciseName: item['ExerciseName'].S!,
        status: item['Status'].S!,
        planName: item['PlanName'].S!,
      });
    });
  }

  public async getUserExercisePlanStatus(assignedPlanId: string, email: string, statuses: TaskStatus[]): Promise<ExerciseStatus[]> {
    const expressionAttributeValues: Record<string, AttributeValue> = { ':ep': { S: `${email}#${assignedPlanId}` } };
    let filterExpression = '';

    if (statuses.length > 1) {
      for (let i = 0; i < statuses.length; i++) {
        expressionAttributeValues[`:status${i}`] = { S: statuses[i] };

        if (i == 0) {
          filterExpression += `#Status BETWEEN :status${i} `;
        } else {
          filterExpression += `AND :status${i}`;
        }
      }
    } else {
      expressionAttributeValues[':status'] = { S: statuses[0] };
      filterExpression += '#Status = :status';
    }

    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      KeyConditionExpression: 'UserEmail_AssignedPlanID = :ep',
      IndexName: 'UserIndex',
      FilterExpression: filterExpression,
      ExpressionAttributeValues: expressionAttributeValues,
      ProjectionExpression: 'ExerciseID, UrlID',
      ExpressionAttributeNames: {
        '#Status': 'Status',
      },
    });

    const data = await this.client.send(queryCommand);
    if (!data || !data.Items)
      return [];

    return (data.Items ?? []).map(item => new ExerciseStatus({
      userEmail: email,
      assignedPlanID: assignedPlanId,
      exerciseID: item['ExerciseID'].S!,
      urlID: item['UrlID'].S!,
    }));
  }

  public async getUserExercisesPlanStatus(assignedPlanId: string, email: string): Promise<Array<ExerciseStatus>> {
    const queryCommand = new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      KeyConditionExpression: 'UserEmail_AssignedPlanID = :ep',
      IndexName: 'UserIndex',
      ExpressionAttributeValues: {
        ':ep': { S: `${email}#${assignedPlanId}` },
      },
      ExpressionAttributeNames: {
        '#Status': 'Status',
      },
      ProjectionExpression: 'AssignedPlanID, ExerciseID, UrlID, ExerciseName, #Status, ExerciseDifficulty, UsedHintsCount, MaxExecutionTime, StartTime'
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new ExerciseStatus({
        userEmail: email,
        assignedPlanID: item['AssignedPlanID'].S!,
        exerciseID: item['ExerciseID'].S!,
        urlID: item['UrlID'].S!,
        status: item['Status'].S!,
        exerciseName: item['ExerciseName'].S!,
        exerciseDifficulty: item['ExerciseDifficulty'].S!,
        startTime: item['StartTime']?.S,
        usedHintsCount: parseInt(item['UsedHintsCount']?.N ?? '0'),
        maxExecutionTime: parseInt(item['MaxExecutionTime']?.N ?? '0'),
      });
    });
  }

  public async getUserExerciseStatus(assignedPlanID: string, exerciseID: string, userEmail: string): Promise<ExerciseStatus> {
    const output = await this.client.send(new QueryCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoExerciseStatus : DynamoDBTable.ExerciseStatus,
      KeyConditionExpression: 'UserEmail_AssignedPlanID = :emailID AND ExerciseID = :eid',
      IndexName: 'UserIndex',
      ExpressionAttributeValues: {
        ':emailID': { S: `${userEmail}#${assignedPlanID}` },
        ':eid': { S: exerciseID },
      },
    }));

    if (!output.Items)
      throw new Error('Exercise not found');


    return new ExerciseStatus({
      assignedPlanID,
      userEmail,
      exerciseID,
      startTime: output.Items[0]['StartTime']?.S,
      interviewerCompanyId: output.Items[0]['InterviewerCompanyID']?.S,
      validationCount: parseInt(output.Items[0]['ValidationCount']?.N ?? '0'),
      usedHintsCount: parseInt(output.Items[0]['UsedHintsCount']?.N ?? '0'),
      remainingTime: parseInt(output.Items[0]['RemainingTime']?.N ?? '0'),
      maxExecutionTime: parseInt(output.Items[0]['MaxExecutionTime']?.N ?? '0'),
      status: output.Items[0]['Status']?.S,
      deadline: output.Items[0]['Deadline']?.S,
      isPlanCanceled: output.Items[0]['IsCanceled']?.BOOL,
      isPlanRejected: output.Items[0]['IsRejected']?.BOOL,
      isPlanFinished: output.Items[0]['IsPlanFinished']?.BOOL,
    });
  }

  public async getExercise(id: string): Promise<Exercise | null> {
    const command = new GetItemCommand({
      TableName: DynamoDBTable.Exercise,
      Key: {
        'exerciseId': { S: id }
      },
      ProjectionExpression: 'Description, DynamicDescription, ExecutionTime, ExerciseName, ExerciseNamePretty, NeedTerminal, NeedCodeEditor, ShortDescription, Difficulty'
    });

    const data = await this.client.send(command);
    if (!data || !data.Item) {
      return null;
    }

    return {
      id: id,
      tags: [],
      userFriendlyName: data.Item['ExerciseNamePretty'].S!,
      name: data.Item['ExerciseName'].S!,
      description: data.Item['Description'].S!,
      difficulty: data.Item['Difficulty'].S!,
      shortDescription: data.Item['ShortDescription'].S!,
      dynamicDescription: data.Item['DynamicDescription']?.BOOL ?? false,
      needCodeEditor: data.Item['NeedCodeEditor']?.BOOL ?? false,
      needTerminal: data.Item['NeedTerminal']?.BOOL ?? false,
      executionTime: parseInt(data.Item['ExecutionTime'].N!),
    };
  }

  public async getExerciseNames(exerciseIds: string[]): Promise<Array<Exercise>> {
    const keys: { [key: string]: AttributeValue }[] = exerciseIds.map(id => ({
      'exerciseId': { S: id }
    }));

    const command = new BatchGetItemCommand({
      RequestItems: {
        [DynamoDBTable.Exercise]: {
          Keys: keys,
          ProjectionExpression: 'ExerciseNamePretty, exerciseId'
        },
      }
    });

    try {
      const data = await this.client.send(command);
      if (!data.Responses) {
        throw new Error('Exercises not found! Please try again later.');
      }

      return (data.Responses[DynamoDBTable.Exercise] ?? []).map(item => ({
        id: item['exerciseId'].S!,
        userFriendlyName: item['ExerciseNamePretty'].S!,
        imageLink: `${environment.ExercisePicturePath}${item['exerciseId'].S!}.jpeg`,
        name: item['ExerciseName']?.S ?? '',
        description: item['Description']?.S ?? '',
        tags: item['Tags']?.SS ?? [],
        difficulty: item['Difficulty']?.S ?? '',
        hints: (item['Hints']?.L ?? []).map(item => item.S!),
        needTerminal: item['NeedTerminal']?.BOOL ?? false,
        needCodeEditor: item['NeedCodeEditor']?.BOOL ?? false,
        dynamicDescription: item['DynamicDescription']?.BOOL ?? false,
        shellType: item['ShellType']?.S ?? '',
        shortDescription: item['ShortDescription']?.S ?? '',
        environmentChartName: item['EnvironmentChartName']?.S ?? '',
        referenceSolutionChartName: item['ReferenceSolutionChartName']?.S ?? '',
        validationTestsChartName: item['ValidationTestsChartName']?.S ?? '',
        initializationChartName: item['InitializationChartName']?.S ?? '',
        environmentChartVersion: item['EnvironmentChartVersion']?.S ?? '',
        referenceSolutionChartVersion: item['ReferenceSolutionChartVersion']?.S ?? '',
        validationTestsChartVersion: item['ValidationTestsChartVersion']?.S ?? '',
        executionTime: parseInt(item['ExecutionTime']?.N ?? '0'),
      }));
    } catch (e) {
      throw new Error('Failed to fetch exercises');
    }
  }

  public async getAssignedPlan(userEmail: string, companyId: string, assignedPlanId: string): Promise<AssignedPlan> {
    const getItemCommand = new GetItemCommand({
      TableName: await this.isDemo ? DynamoDBTable.DemoAssignedPlan : DynamoDBTable.AssignedPlan,
      Key: {
        InterviewerCompanyID: { S: companyId },
        UserEmail_AssignedPlanID: { S: `${userEmail}#${assignedPlanId}` },
      },
      ProjectionExpression: 'PlanID, PlanName, InterviewerFullName, InterviewerEmail, UserFullName, Deadline, AssignedDate, PricePerSecond, IsPaid, ExerciseStatuses, ExerciseExecutionTime, IsRejected, IsCanceled, IsFinished'
    });

    try {
      const output = await this.client.send(getItemCommand);
      if (!output.Item) {
        throw new Error('Assigned plan not found');
      }

      return new AssignedPlan({
        interviewerCompanyID: companyId,
        userEmail,
        id: assignedPlanId,
        userFullName: output.Item['UserFullName'].S,
        planID: output.Item['PlanID'].S,
        planName: output.Item['PlanName'].S,
        interviewerFullName: output.Item['InterviewerFullName'].S,
        interviewerEmail: output.Item['InterviewerEmail'].S,
        deadline: output.Item['Deadline'].S,
        assignedDate: output.Item['AssignedDate'].S,
        isPaid: output.Item['IsPaid']?.BOOL ?? false,
        isRejected: output.Item['IsRejected']?.BOOL ?? false,
        isCanceled: output.Item['IsCanceled']?.BOOL ?? false,
        isFinished: output.Item['IsFinished']?.BOOL ?? false,
        pricePerSecond: parseFloat(output.Item['PricePerSecond'].N!) ?? 0,
        exerciseStatusesMap: this.DynamoDBMapToMap<string>(output.Item['ExerciseStatuses'].M!),
        exerciseExecutionTime: this.DynamoDBMapToMap<number>(output.Item['ExerciseExecutionTime'].M!),
      });
    } catch (err) {
      throw new Error('Failed to load assigned plan');
    }
  }

  public async getInvitedUserConnections(companyId: string): Promise<UserPublicProfile[]> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.InviteLink,
      KeyConditionExpression: 'companyId = :ci',
      FilterExpression: 'InviteType = :u',
      ProjectionExpression: 'inviteId, Email',
      ExpressionAttributeValues: {
        ':ci': { S: companyId },
        ':u': { S: 'user' },
      },
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new UserPublicProfile({
        email: item['Email'].S!,
        inviteId: item['inviteId'].S,
      });
    });
  }

  public async getCompanyConnection(companyId: string, email: string): Promise<UserPublicProfile> {
    const output = await this.client.send(new GetItemCommand({
      TableName: DynamoDBTable.CompanyConnections,
      ProjectionExpression: 'FirstName, LastName, PictureLink, CountryCode, PhoneNumber',
      Key: {
        companyId: { S: companyId },
        email: { S: email }
      },
    }));

    if (!output.Item) {
      throw new Error('Connection not found');
    }

    return new UserPublicProfile({
      email,
      firstName: output.Item['FirstName']?.S,
      lastName: output.Item['LastName']?.S,
      picture: output.Item['PictureLink']?.S,
      countryCode: output.Item['CountryCode']?.S,
      phoneNumber: output.Item['PhoneNumber']?.S
    });
  }

  public async getCompanyConnections(companyId: string): Promise<UserPublicProfile[]> {
    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.CompanyConnections,
      KeyConditionExpression: 'companyId = :ci',
      ExpressionAttributeValues: {
        ':ci': { S: companyId }
      },
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => {
      return new UserPublicProfile({
        email: item['email'].S!,
        firstName: item['FirstName'].S,
        lastName: item['LastName'].S,
        picture: item['PictureLink'].S,
        countryCode: item['CountryCode'].S,
        phoneNumber: item['PhoneNumber'].S,
        role: 'user'
      });
    });
  }

  public async getCompanyNotifications(companyId: string, limit: number, lastKey: string | undefined): Promise<Array<Notification>> {
    let startKey: { [key: string]: AttributeValue; } | undefined = undefined;
    if (lastKey) {
      startKey = {
        'date': { S: lastKey },
        'companyId': { S: companyId },
      };
    }

    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.CompanyNotifications,
      KeyConditionExpression: 'companyId = :ci',
      ProjectionExpression: '#date, Message, PictureLink, RedirectLink',
      ExpressionAttributeValues: {
        ':ci': { S: companyId }
      },
      ExpressionAttributeNames: {
        '#date': 'date'
      },
      Limit: limit,
      ScanIndexForward: false,
      ExclusiveStartKey: startKey
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => ({
      date: item['date'].S!,
      message: item['Message'].S!,
      pictureLink: item['PictureLink']?.S || environment.DefaultMemberPicture,
      redirectLink: item['RedirectLink'].S!,
      id: uuidv4()
    }));
  }

  public async getUserNotifications(email: string, limit: number, lastKey: string | undefined): Promise<Array<Notification>> {
    let startKey: { [key: string]: AttributeValue; } | undefined = undefined;
    if (lastKey) {
      startKey = {
        'date': { S: lastKey },
        'email': { S: email },
      };
    }

    const queryCommand = new QueryCommand({
      TableName: DynamoDBTable.UserNotifications,
      KeyConditionExpression: 'email = :e',
      ProjectionExpression: '#date, Message, PictureLink, RedirectLink',
      ExpressionAttributeValues: {
        ':e': { S: email }
      },
      ExpressionAttributeNames: {
        '#date': 'date'
      },
      Limit: limit,
      ScanIndexForward: false,
      ExclusiveStartKey: startKey
    });

    const queryOutput = await this.client.send(queryCommand);
    return (queryOutput.Items ?? []).map(item => ({
      date: item['date'].S!,
      message: item['Message'].S!,
      pictureLink: item['PictureLink']?.S || environment.DefaultMemberPicture,
      redirectLink: item['RedirectLink'].S!,
      id: uuidv4()
    }));
  }

  public async isDemoMode(): Promise<boolean> {
    const getCommand = new GetItemCommand({
      TableName: DynamoDBTable.DemoMode,
      Key: { modeID: { S: 'CurrentMode' } },
    });
    const getOutput = await this.client.send(getCommand);
    if (!getOutput.Item)
      throw new Error('Demo mode item not found!');

    return getOutput.Item['isDemo']?.BOOL ?? false;
  }

  public async saveDemoExerciseStatus(data: ExerciseData): Promise<void> {
    await Promise.all([
      this.client.send(new UpdateItemCommand({
        TableName: DynamoDBTable.DemoExerciseStatus,
        Key: {
          InterviewerCompanyID_AssignedPlanID_UserEmail: { S: `${data.CompanyId}#${data.AssignedPlanId}#${data.UserEmail}` },
          ExerciseID: { S: data.ExerciseId },
        },
        ExpressionAttributeValues: {
          ':status': { S: data.ExerciseStatus },
          ':startTime': { S: data.StartTime },
          ':completionTime': { S: data.CompletionTime },
          ':usedHintsCount': { N: `${data.UsedHintsCount}` },
          ':validationCount': { N: `${data.ValidationCount}` },
          ':remainingTime': { N: `${data.RemainingTime}` }
        },
        ExpressionAttributeNames: {
          '#status': 'Status'
        },
        UpdateExpression: 'set #status = :status, CompletionTime = :completionTime, StartTime = :startTime, ValidationCount = :validationCount, UsedHintsCount = :usedHintsCount, RemainingTime = :remainingTime',
      })),

      this.client.send(new UpdateItemCommand({
        TableName: DynamoDBTable.DemoAssignedPlan,
        Key: {
          InterviewerCompanyID: { S: data.CompanyId },
          UserEmail_AssignedPlanID: { S: `${data.UserEmail}#${data.AssignedPlanId}` },
        },
        ExpressionAttributeValues: {
          ':status': { S: data.ExerciseStatus },
          ':executionTime': { N: `${data.ExecutionTime}` },
          ':isPlanFinished': { BOOL: data.IsPlanFinished }
        },
        ExpressionAttributeNames: {
          '#exerciseId': data.ExerciseId
        },
        UpdateExpression: 'set ExerciseStatuses.#exerciseId = :status, ExerciseExecutionTime.#exerciseId = :executionTime, IsFinished = :isPlanFinished'
      }))
    ]);
  }

  public async setDemoPlanStatusToRejected(companyId: string, userEmail: string, assignedPlanId: string): Promise<void> {
    this.client.send(new UpdateItemCommand({
      TableName: DynamoDBTable.DemoAssignedPlan,
      Key: {
        InterviewerCompanyID: { S: companyId },
        UserEmail_AssignedPlanID: { S: `${userEmail}#${assignedPlanId}` },
      },
      ExpressionAttributeValues: {
        ':true': { BOOL: true }
      },
      UpdateExpression: 'set IsRejected = :true'
    }));
  }

  public async setDemoPlanStatusToFinished(companyId: string, userEmail: string, assignedPlanId: string): Promise<void> {
    this.client.send(new UpdateItemCommand({
      TableName: DynamoDBTable.DemoAssignedPlan,
      Key: {
        InterviewerCompanyID: { S: companyId },
        UserEmail_AssignedPlanID: { S: `${userEmail}#${assignedPlanId}` },
      },
      ExpressionAttributeValues: {
        ':true': { BOOL: true }
      },
      UpdateExpression: 'set IsFinished = :true'
    }));
  }

  public async setDemoPlanStatusToCanceled(companyId: string, userEmail: string, assignedPlanId: string): Promise<void> {
    this.client.send(new UpdateItemCommand({
      TableName: DynamoDBTable.DemoAssignedPlan,
      Key: {
        InterviewerCompanyID: { S: companyId },
        UserEmail_AssignedPlanID: { S: `${userEmail}#${assignedPlanId}` },
      },
      ExpressionAttributeValues: {
        ':true': { BOOL: true }
      },
      UpdateExpression: 'set IsCanceled = :true'
    }));
  }
}
