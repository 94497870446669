import { MonacoTreeElement } from 'ngx-monaco-tree';
import { Node } from './models/node.interface';

type NodeChildren = Map<string, FileSystemNode>

export class FileSystemNode {
  private children?: NodeChildren;

  get child(): NodeChildren | undefined {
    return this.children;
  }

  constructor(nodes?: Node[]) {
    if (nodes) {
      this.children = new Map<string, FileSystemNode>();

      for (const node of nodes) {
        this.addChild(node);
      }
    }
  }

  private addChild(nodeData: Node): void {
    let newNode: FileSystemNode;
    if (nodeData.content) {
      newNode = new FileSystemNode(nodeData.content);
    } else {
      newNode = new FileSystemNode(nodeData.isDir ? [] : undefined);
    }
        this.children!.set(nodeData.name, newNode);
  }

  public DeleteFile(path: string): void {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return;
    }

    if (!this.children) {
      return;
    }

    if (pathParts.length === 0) {
      this.children.delete(currentPart);
      return;
    }

    this.children.get(currentPart)?.DeleteFile(pathParts.join('/'));
  }

  public isDirectory(path: string): boolean {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return false;
    }

    if (pathParts.length === 0) {
      return !!this.children?.get(currentPart)?.children;
    }

    return this.children?.get(currentPart)?.isDirectory(pathParts.join('/')) ?? false;
  }

  public AddFile(name: string, path: string, isDir: boolean): void {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return;
    }

    if (!this.children) {
      return;
    }

    if (pathParts.length === 0) {
      this.children.set(name, new FileSystemNode(isDir ? [] : undefined));
      return;
    }

    let nextNode = this.children.get(currentPart);
    if (!nextNode) {
      nextNode = new FileSystemNode([]);
      this.children.set(currentPart, nextNode);
    }

    path = pathParts.join('/');
    nextNode.AddFile(name, path, isDir);
  }

  public Find(path: string): FileSystemNode | undefined {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return;
    }

    if (pathParts.length === 0) {
      return this.children?.get(currentPart);
    }

    path = pathParts.join('/');
    return this.children?.get(currentPart)?.Find(path);
  }

  public toMonacoTreeElement(): MonacoTreeElement[] | undefined {
    if (!this.children) return;

    return Array.from(this.children.entries()).map(([name, childNode]) => ({
      name: name,
      content: childNode.toMonacoTreeElement()
    }));
  }
}