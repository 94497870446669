<div class="wrapper">
    <div class="title">Create Plan</div>
    <div class="details">
        <div class="details-title">
            <span class="subtitle">Plan Name</span>
        </div>
        <textarea
            pInputTextarea 
            class="invisible-input focus:border-900 focus:shadow-none hover:shadow-none hover:border-900 text-xs"
            placeholder="Senior DevOps engineer"
            type="text"
            maxlength="100"
            rows="2"
            [autoResize]="true"
            [formControl]="nameControl.control">
        </textarea>
        <div class="counter">
            <span [ngClass]="{'invalid-input': nameControl.isMinLengthError()}">
                {{nameControl.value.length}}
            </span>
            <span>/ 100</span>
        </div>
    </div>
    <div class="details">
        <div class="details-title small-bottom-indent">
            <span class="subtitle">Plan Description</span>
            <app-help [helpMessage]="helpMessageEnum.PlanDescription"></app-help>
        </div>
        <textarea
            pInputTextarea 
            class="invisible-input focus:border-900 focus:shadow-none hover:shadow-none hover:border-900 text-xs"
            placeholder="Hard and medium difficulty exercises"
            maxlength="2000"
            type="text"
            rows="4"
            [autoResize]="true"
            [formControl]="descriptionControl.control">
        </textarea>
        <div class="counter">
            <span [ngClass]="{'invalid-input': descriptionControl.isMinLengthError()}">
                {{descriptionControl.value.length}}
            </span>
            <span>/ 2000</span>
        </div>
    </div>
    <div class="details">
        <div class="details-title bottom-indent">
            <span class="subtitle">Exercises</span>
            <app-help [helpMessage]="helpMessageEnum.Exercises"></app-help>
        </div>
        <ul class="text-xs pl-3">
            <li *ngFor="let exercise of this.planData.selectedPlan?.exerciseNames">
                {{exercise}}
            </li>
        </ul>
        <div class="manage-exercise-link">
            <a (click)="openExercisePlanDialog()">Add exercises</a>
        </div>
    </div>
    <!-- <div class="details">
        <div class="details-title bottom-indent">
            <span class="subtitle">Picture</span>
            <app-help [helpMessage]="helpMessageEnum.Picture"></app-help>
        </div>
        <img class="plan-image" [src]="imageLink">
    </div> -->
    <div class="details">
        <simple-button 
            (click)="savePlan()">
            Save
        </simple-button>
    </div>
</div>