import { Component, OnDestroy, OnInit } from '@angular/core';
import { HintMessages } from 'src/app/plans-management/plan-editor/help/help.component';
import { ExercisePlansDataService } from '../exercise-plans-data-service/exercise-plans-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TaskStatus } from 'src/app/status-parser';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';
import { Router } from '@angular/router';
import { ExerciseStatus } from 'src/app/models/exercise-status.model';
import { ScrollToTop } from 'src/app/shared/service/ui';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-user-exercise-plans-info-panel',
  templateUrl: './user-exercise-plans-info-panel.component.html',
  styleUrls: ['./user-exercise-plans-info-panel.component.scss']
})
export class UserExercisePlansInfoPanelComponent implements OnInit, OnDestroy {
  public helpMessageEnum = HintMessages;
  public statusEnum = TaskStatus;
  public plan: AssignedPlan | null | undefined;
  public skeletonItems: number[] = [1, 2, 3];
  private selectedPlanSubscription: Subscription = new Subscription();
  public user: User = this.auth.user;
  public isDemoMode = false;

  constructor(
    private exercisePlansData: ExercisePlansDataService,
    private auth: AuthService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private demoModeService: DemoModeService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isDemoMode = await this.demoModeService.isDemo;
    this.subToSelectedPlan();
  }

  ngOnDestroy(): void {
    this.selectedPlanSubscription.unsubscribe();
  }

  private get uncompletedExercises(): number {
    if (!this.plan?.exerciseStatuses)
      return 0;

    return this.plan.exerciseStatuses.reduce((total, current) =>
      (current.status === TaskStatus.NOT_STARTED || current.status === TaskStatus.RUNNING) ? total + 1 : total, 0);
  }

  public rejectPlan(plan: AssignedPlan): void {
    this.confirmationService.confirm({
      header: plan.isFinished ? 'Want to delete the plan?' : 'Want to reject the plan?',
      message: plan.isFinished ?
        `Are you sure you want to delete the "${plan.planName}" plan? This will remove it from your list.` :
        `Are you sure you want to reject the "${plan.planName}" plan? This will remove it from your list and indicate to the interviewer that you have rejected this plan.`,
      accept: async () => {
        await this.exercisePlansData.delete(plan);
        ScrollToTop('scrollTo');
      },
      reject: () => {
        return;
      }
    });
  }

  public finish(plan: AssignedPlan): void {
    this.confirmationService.confirm({
      dismissableMask: true,
      header: 'Finish plan?',
      message: `Are you sure you want to finish this plan?
                You still have ${this.uncompletedExercises} ${this.uncompletedExercises > 1 ? 'exercises' : 'exercise'} left to complete. 
                Would you like to finish now?`,
      accept: async () => {
        await this.exercisePlansData.finish(plan);
      },
      reject: () => {
        return;
      }
    });
  }

  private subToSelectedPlan(): void {
    this.selectedPlanSubscription.add(
      this.exercisePlansData.selectedData$.subscribe(plan => {
        if (plan != null)
          this.handlePlanSelection(plan);
        else
          this.plan = plan;
      })
    );
  }

  private async handlePlanSelection(plan: AssignedPlan | null): Promise<void> {
    if (!plan)
      return;

    try {
      this.plan = plan;
      await this.plan.fetchExerciseStatuses();
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sorry, we couldn\'t retrieve your exercise details. Please try again later' });
    }
  }

  public openExercise(exercise: ExerciseStatus): void {
    try {
      this.plan?.updateTaskStatus(exercise.exerciseID, TaskStatus.RUNNING);
      this.router.navigate([`/exercise/${exercise.urlID}/${exercise.exerciseID}/${exercise.assignedPlanID}`]);
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, something went wrong. Please try again later.'
      });
    }
  }
}
