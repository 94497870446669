import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { LambdasService } from 'src/app/lambads.service';
import { User } from 'src/app/user.module';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GetQueryParam } from 'src/app/shared/service/route';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Injectable({
  providedIn: 'root'
})
export class ExercisePlansDataService {
  private user: User;
  private dataSubject: BehaviorSubject<AssignedPlan[] | null> = new BehaviorSubject<AssignedPlan[] | null>(null);
  public data$: Observable<AssignedPlan[] | null> = this.dataSubject.asObservable();
  private selectedDataSubject: BehaviorSubject<AssignedPlan | null | undefined> = new BehaviorSubject<AssignedPlan | null | undefined>(undefined);
  public selectedData$: Observable<AssignedPlan | null | undefined> = this.selectedDataSubject.asObservable();

  constructor(
    private dynamodb: DynamoDBService,
    private lambdas: LambdasService,
    private auth: AuthService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private demoModeService: DemoModeService,
  ) {
    this.user = auth.user;
    this.fetch();
  }

  get data(): AssignedPlan[] {
    return this.dataSubject.getValue() || [];
  }

  get selectedData(): AssignedPlan | null | undefined {
    return this.selectedDataSubject.getValue();
  }

  public selectPlanById(id: string): void {
    const plan = this.data.find(p => p.id === id);
    if (plan) {
      this.setSelectedData(plan);
    }
  }

  public setSelectedData(plan: AssignedPlan): void {
    this.selectedDataSubject.next(plan);
    this.modifyQuery(plan);
  }

  private setEmptySelection(): void {
    this.selectedDataSubject.next(null);
  }

  public async fetch(): Promise<void> {
    try {
      const data = await this.dynamodb.getUserExercisesPlans(this.user.email);
      this.dataSubject.next(data);
      this.selectInitPlan();
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sorry, we couldn\'t retrieve your exercise plans' });
    }
  }

  public async delete(plan: AssignedPlan): Promise<void> {
    try {
      if (await this.demoModeService.isDemo)
        await this.dynamodb.setDemoPlanStatusToRejected(plan.interviewerCompanyID, this.user.email, plan.id);
      else
        await this.lambdas.rejectAssignedPlan(plan.id).toPromise();

      this.removeFromArray(plan);

      if (this.data.length > 0) {
        this.setSelectedData(this.data[0]);
      } else {
        this.setEmptySelection();
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The plan was successful deleted' });
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: (err as Error).message });
    }
  }

  public async finish(plan: AssignedPlan): Promise<void> {
    try {
      if (await this.demoModeService.isDemo)
        await this.dynamodb.setDemoPlanStatusToFinished(plan.interviewerCompanyID, this.user.email, plan.id);
      else
        await this.lambdas.finishAssignedPlan(plan.id).toPromise();

      plan.isFinished = true;
      plan.resetCache();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The plan has been successfully completed. A notification has been sent to the interviewer' });
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: (err as Error).message });
    }
  }

  private selectInitPlan(): void {
    if (this.data.length === 0) {
      this.setEmptySelection();
      return;
    }

    const queryParam = GetQueryParam(this.activatedRoute, 'id');

    if (queryParam) {
      this.selectPlanById(queryParam);
    } else {
      this.setSelectedData(this.data[0]);
    }
  }

  private modifyQuery(plan: AssignedPlan): void {
    this.router.navigate([], { queryParams: { id: plan.id } });
  }

  private removeFromArray(plan: AssignedPlan): void {
    const newData = this.data.filter(p => p.id !== plan.id);
    this.dataSubject.next(newData);
  }
}
