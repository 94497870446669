<div class="wrapper">
    <div class="title">Plan Editor</div>
    <div class="details">
        <div class="details-title">
            <span class="subtitle">Plan Name</span>
            <button 
              class="bg-white border-none flex justify-content-center p-0"
              [disabled]="isNameInputVisible"
              (click)="openNameInput()">
              <i class="material-icons">edit</i>
            </button>
        </div>
        <textarea
            #nameInput
            cdkTextareaAutosize
            *ngIf="isNameInputVisible"
            class="invisible-input" 
            type="text"
            placeholder="My Awesome Plan"
            maxlength="100"
            [formControl]="nameControl.control"
            (blur)="saveName()">
        </textarea>
        <div 
          class="counter"
          *ngIf="isNameInputVisible">
            <span [ngClass]="{'invalid-input': nameControl.isMinLengthError()}">
                {{nameControl.value.length}}
            </span>
            <span>/ 100</span>
        </div>
        <span 
            *ngIf="!isNameInputVisible"
            class="text">
            {{editedPlan?.name}}
        </span>
    </div>
    <div class="details">
        <div class="details-title small-bottom-indent ">
            <span class="subtitle">Plan Description</span>
            <app-help [helpMessage]="helpMessageEnum.PlanDescription"></app-help>
            <button 
              class="bg-white border-none flex justify-content-center p-0"
              [disabled]="isDescriptionInputVisible"
              (click)="openDescriptionInput()">
              <i class="material-icons">edit</i>
            </button>
        </div>
        <textarea
            #descriptionInput
            cdkTextareaAutosize
            *ngIf="isDescriptionInputVisible"
            class="invisible-input text" 
            maxlength="2000"
            placeholder="Describe a plan here"
            type="text"
            [formControl]="descriptionControl.control"
            (blur)="saveDescription()">
        </textarea>
        <div 
          class="counter"
          *ngIf="isDescriptionInputVisible">
            <span [ngClass]="{'invalid-input': descriptionControl.isMinLengthError()}">
                {{descriptionControl.value.length}}
            </span>
            <span>/ 2000</span>
        </div>
        <div 
            *ngIf="!isDescriptionInputVisible"
            class="text">
            {{editedPlan?.description}}
        </div>
    </div>
    <div class="details">
        <div class="details-title bottom-indent">
            <span class="subtitle">Exercises</span>
            <app-help [helpMessage]="helpMessageEnum.Exercises"></app-help>
        </div>
        <ul class="text-xs pl-3">
            <li *ngFor="let exercise of this.planData.selectedPlan?.exerciseNames">
                {{exercise}}
            </li>
        </ul>
        <div class="manage-exercise-link">
            <a (click)="openExercisePlanDialog()">Manage exercises</a>
        </div>
    </div>
    <!-- <div class="details">
        <div class="details-title bottom-indent">
            <span class="subtitle">Picture</span>
            <app-help [helpMessage]="helpMessageEnum.Picture"></app-help>
        </div>
        <img class="plan-image" [src]="editedPlan?.imageLink || imageLink">
    </div> -->
    <div class="details">
        <div class="details-title small-bottom-indent">
            <span class="subtitle">Info</span>
        </div>
        <div class="text plan-info">
            <span>Created: {{editedPlan?.creationTime | date: 'MMM d, y, HH:mm'}}</span>
            <span>Modified: {{editedPlan?.lastModificationTime | date: 'MMM d, y, HH:mm'}}</span>
            <span>Author: {{editedPlan?.interviewerFullName}}</span>
        </div>
    </div>
    <div class="details">
        <simple-button
          (click)="saveChanges()">
            Save
        </simple-button>
    </div>
</div>