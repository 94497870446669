import { Component, OnDestroy, OnInit } from '@angular/core';
import { DemoModeService } from '../../demo-mode.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'demo-alarm',
  templateUrl: './demo-alarm.component.html'
})
export class DemoAlarmComponent implements OnInit, OnDestroy {
  public isDemoMode = false;
  public isClosed = false;
  private isDemoSub: Subscription | null = null;

  constructor(
    private demoModeService: DemoModeService,
    private dialogService: DialogService
  ) { }

  async ngOnInit(): Promise<void> {
    this.subToDemoMode();
  }

  ngOnDestroy(): void {
    this.isDemoSub?.unsubscribe();
  }

  private subToDemoMode(): void {
    this.demoModeService.isDemo$.subscribe(isDemo => {
      this.isDemoMode = isDemo;
    });
  }

  public openSupportDialog(): void {
    this.dialogService.open(SupportDialogComponent, {
      width: '550px',
      baseZIndex: 10000,
      showHeader: true,
      dismissableMask: true,
      header: 'Submit Ticket'
    });
  }
}
