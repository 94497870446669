<section>
    <div class="flex justify-content-end mb-2">
        <button class="action-button p-0 pr-2" (click)="create('', 'file')">
            <span class="material-icons-outlined text-lg">note_add</span>
        </button>
        <button class="action-button p-0" (click)="create('', 'directory')">
            <span class="material-icons-outlined text-xl">create_new_folder</span>
        </button>
    </div>
    <div width="100%" height="100%">
        <monaco-tree
          *ngIf="tree"
          [width]="'100%'" 
          [height]="'100%'" 
          [tree]="tree.tree"
          [ngClass]="{'blocked-button': !isExerciseStarted}"
          (clickFile)="openFile($event)"
          (clickContextMenu)="onContextMenuClick($event)">
        </monaco-tree>
    </div>
</section>

<p-confirmDialog #cd 
      [style]="{width: '500px'}"
      
      [key]=dialogKey>
        <ng-template pTemplate="footer">
            <div>
                <exercise-button class="mr-3" (click)="cd.reject()">No</exercise-button>
                <exercise-button (click)="cd.accept()">Yes</exercise-button>
            </div>
        </ng-template>
</p-confirmDialog>