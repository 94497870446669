<div class="wrapper">
    <h4 class="title" *ngIf="user.isInterviewer">Exercise Plans</h4>
    <h4 class="title" *ngIf="user.isUser">Plans Assigned To You</h4>
    <div class="container" #planList>
        <ng-container *ngIf="plans !== null; else skeleton">
            <a class="plan" 
              [routerLink]="user.isInterviewer ? '/plans-management' : '/plans'" 
              [queryParams]="{id: plan.id}" 
              *ngFor="let plan of widgetData.data$ | async">
                <img class="plan-img" [src]="plan.picture">
                <div class="plan-info">
                    <h5 class="plan-name text-base">{{plan.name}}</h5>
                    <span #description class="plan-description text-sm">
                        <span class="font-semibold">Description: </span>
                        <span>{{plan.description}}</span>
                    </span>
                    <div 
                      *ngIf="user.isUser"
                      class="text-900 text-sm">
                        <span class="font-semibold">Deadline: </span>
                        <span>{{plan.deadline | date: 'MMM d, y, h:mm a'}}</span>
                    </div>
                </div>
            </a>
        </ng-container>

        <div *ngIf="plans && plans.length > 0" 
          class="h-100 flex justify-content-center align-items-end">
            <a class="plans-management-link text-sm" 
              routerLink="/plans-management"
              *ngIf="user.isInterviewer">
                Plans Management
            </a>
    
            <a class="plans-management-link text-sm"
              routerLink="/plans"
              *ngIf="user.isUser">
                More Plans
            </a>
        </div>

        <div class="no-plan-container"
          *ngIf="plans && plans.length === 0">
            <ng-container *ngIf="user.isInterviewer">
                <h5 class="small-title">There are no exercise plans</h5>
                <div class="no-plan-message">
                    Click <a routerLink="/plans-management">Plans Management</a> to open the plan management tab, where you can create, modify,
                    or delete plans.
                </div>
            </ng-container>
            <ng-container *ngIf="user.isUser">
                <h5 class="small-title">There are no plans assigned to you</h5>
                <div class="no-plan-message">
                    Please wait for a request from a company that can assign an exercise plan, or wait for a new feature
                    that will allow you to create your own plans.
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #skeleton>
   <div *ngFor="let _ of skeletonArray" class="plan">
        <p-skeleton class="plan-img" width="55px" height="55px"></p-skeleton>
        <div class="plan-info">
            <h5 class="plan-name mb-1"><p-skeleton width="15%" height="14px"></p-skeleton></h5>
            <div class="mb-1"><p-skeleton width="60%" height="12px"></p-skeleton></div>
            <p-skeleton width="15%" height="12px"></p-skeleton>
        </div>
   </div>
</ng-template>