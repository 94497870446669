import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { Command, EditorEvent, Message, Status, Type, File } from '../../../../exercise.component';
import { ContextMenuAction } from 'ngx-monaco-tree';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Tree } from './tree';
import { FileEventType } from './models/file-event-type.enum';
import { FileSystemNode } from './file-system-node';
import * as uuid from 'uuid';
import { DemoExerciseDataService } from 'src/app/exercise/demo/services/demo-exercise-data/demo-exercise-data.service';
import { IExerciseDataService } from 'src/app/exercise/demo/models/exercise-data-interface';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-file-watcher',
  templateUrl: './file-watcher.component.html',
  styleUrls: ['./file-watcher.component.scss']
})
export class FileWatcherComponent implements OnInit, OnDestroy {
  public readonly dialogKey: string = 'delete_confirmation';
  
  private data!: IExerciseDataService;

  private websocketMessageSub: Subscription = new Subscription();
  public tree: Tree | null = null;
  public isExerciseStarted = false;

  private isRenaming = false;

  constructor(
    private _exerciseData: ExerciseDataService,
    private _demoExerciseData: DemoExerciseDataService,
		private _cdr: ChangeDetectorRef,
		private _dialog: ConfirmationService,
		private _renderer: Renderer2,
		private _messageService: MessageService,
    private _demoModeService: DemoModeService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.data = await this._demoModeService.isDemo ? this._demoExerciseData : this._exerciseData;
    this.handleWebSocketMessages();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  public findTreeItemByPath(path: string): HTMLElement | undefined {
    const pathParts = path.split('/');
    if (pathParts.length === 0)
      return;

    const tree = document.querySelector('.monaco-tree');
    if (!tree || !tree.children)
      return;

    const treeElements: HTMLElement[] = Array.from(tree.children) as HTMLElement[];
    return this.findTreeItemRecursive(pathParts, treeElements);
  }

  private findTreeItemRecursive(pathParts: string[], elements: HTMLElement[]): HTMLElement | undefined {
    for (const element of elements) {
      if (!element.firstChild)
        continue;

      const elementText = this.queryTreeName(element.firstChild as HTMLElement)?.textContent;
      if (!elementText)
        continue;

      if (pathParts[0] !== elementText)
        continue;

      pathParts.shift();

      if (pathParts.length === 0)
        return element as HTMLElement;

      return this.findTreeItemRecursive(pathParts, Array.from(element.children) as HTMLElement[]);
    }
    return;
  }

  private queryTreeName(element: HTMLElement): Element | null {
    try {
      return element.querySelector('.monaco-tree-name');
    } catch {
      return null;
    }
  }

  private queryTreeRow(element: HTMLElement): Element | null {
    return element.querySelector('.monaco-tree-row');
  }

  private createInputChild(element: HTMLElement | Element): HTMLInputElement {
    const inputElement: HTMLInputElement = this._renderer.createElement('input');
    this._renderer.addClass(inputElement, 'monaco-tree-input');
    this._renderer.appendChild(element, inputElement);
    return inputElement;
  }

  private setInputValue(value: string, element: HTMLInputElement): void {
    this._renderer.setProperty(element, 'value', value);
  }

  private hideElement(element: HTMLElement | Element): void {
    this._renderer.addClass(element, 'hidden');
  }

  private showElement(element: HTMLElement | Element): void {
    this._renderer.removeClass(element, 'hidden');
  }

  private sendMessage(eventType: EditorEvent, file: File, new_file?: File): void {
    const message: Message = {
      command: Command.Editor,
      editor: {
        type: eventType,
        file,
        new_file
      }
    };
    this.data.ws.sendMessage(JSON.stringify(message));
  }

  private startRenaming(path: string): void {
    this.isRenaming = true;

    const pathParts: string[] = path.split('/');
    const name: string = pathParts.pop() ?? '';

    const monacoTreeItem = this.findTreeItemByPath(path);
    if (!monacoTreeItem)
      return;

    const nameElement: Element | null = this.queryTreeName(monacoTreeItem);
    const rowElement: Element | null = this.queryTreeRow(monacoTreeItem);

    if (!nameElement || !rowElement)
      return;

    const inputElement: HTMLInputElement = this.createInputChild(rowElement);

    const oldName = nameElement!.textContent!.trim();
    this.setInputValue(oldName, inputElement);
    this.hideElement(nameElement);

    const saveName = () => {
      const newName: string = inputElement.value.trim();
      const newPath = `${pathParts.join('/')}/${newName}`;

      if (newName == name)
        return;

      const node: FileSystemNode | undefined = this.tree?.Find(newPath);
      const isDir: boolean | undefined = this.tree?.isDirectory(newPath);
      if (node) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `The ${isDir ? 'directory' : 'file'} with the same name already exists in this folder.`
        });
        return;
      }

      this.sendMessage(EditorEvent.RenameFile, { path }, { path: newPath });
    };

    const listeners: Array<() => void> = [
      this._renderer.listen(inputElement, 'blur', () => {
        saveName();
        cleanup();
      }),
      this._renderer.listen(rowElement, 'keydown', (event) => {
        if (event.key === 'Enter') {
          saveName();
          cleanup();
        }

        if (event.key === 'Escape')
          cleanup();
      })
    ];

    const cleanupListeners = () => {
      listeners.forEach((unlisten) => unlisten());
      listeners.length = 0;
    };

    const cleanup = () => {
      this.showElement(nameElement);
      this._renderer.removeChild(rowElement, inputElement);
      this.isRenaming = false;
      cleanupListeners();
    };

    inputElement.focus();
  }


  public openFile(path: string): void {
    if (this.isRenaming) {
      return;
    }

    if (this.tree && this.tree.isDirectory(path)) {
      return;
    }

    if (!this.isExerciseStarted) {
      return;
    }

    this.sendMessage(EditorEvent.GetFile, { path });
  }

  public onContextMenuClick(contextMenuAction: ContextMenuAction): void {
    const [type, path] = contextMenuAction;
    switch (type) {
    case 'new_file':
      this.create(path, 'file');
      return;
    case 'delete_file':
      this.openDeleteDialog(path);
      return;
    case 'new_directory':
      this.create(path, 'directory');
      return;
    case 'rename_file':
      this.startRenaming(path);
      return;
    default:
      console.log('action not found');
      return;
    }
  }

  private openDeleteDialog(path: string): void {
    const isDir: boolean = this.tree?.isDirectory(path) ?? false;
    const name: string | undefined = path.split('/').pop();

    if (!name)
      return;

    this._dialog.confirm({
      dismissableMask: true,
      key: this.dialogKey,
      header: 'Delete Confirmation',
      message: `
                Are you sure you want to delete '${name}'${isDir ? ' and its content' : ''}?
                After deletion, you will not be able to restore the deleted files.`,
      accept: () => { this.delete(path); },
      reject: () => { return; }
    });
  }

  private delete(path: string): void {
    this.sendMessage(EditorEvent.DeleteFile, { path });
  }

  private openDir(path: string): void {
    const isDirectory: boolean | undefined = this.tree?.isDirectory(path);
    const pathSegments: string[] = path.split('/');

    if (!isDirectory) {
      pathSegments.pop();
    }

    let currentPath = '';

    for (const segment of pathSegments) {
      currentPath = currentPath ? `${currentPath}/${segment}` : segment;

      const element: HTMLElement | undefined = this.findTreeItemByPath(currentPath);
      if (!element)
        continue;

      const row: HTMLElement | null = this.queryTreeRow(element) as HTMLElement | null;
      if (!row)
        continue;

      const arrowIcon: Element | null = row.querySelector('i.monaco-tree-arrow');
      if (arrowIcon?.classList.contains('open'))
        continue;

      row.click();
      this._cdr.detectChanges();
    }
  }

  public create(path: string, type: 'directory' | 'file'): void {
    this.isRenaming = true;

    const placeholderName: string = uuid.v4();
    const isDir: boolean | undefined = this.tree?.isDirectory(path);
    const pathParts: string[] = path.split('/');
    if (!isDir) {
      pathParts.pop();
      path = pathParts.length !== 0 ? pathParts.join('/') : '';
    }


    const placeholderFullPath: string = path.length === 0 ? placeholderName : `${path}/${placeholderName}`;
    this.tree?.AddFile({ path: placeholderFullPath, name: placeholderName, isDir: type === 'directory' });

    this._cdr.detectChanges();
    this.openDir(path);
    const monacoTreeItem: HTMLElement | undefined = this.findTreeItemByPath(placeholderFullPath);

    if (!monacoTreeItem) {
      this.tree?.DeleteFile(placeholderFullPath);
      return;
    }

    const nameElement: Element | null = this.queryTreeName(monacoTreeItem);
    const rowElement: Element | null = this.queryTreeRow(monacoTreeItem);

    if (!nameElement || !rowElement) {
      this.tree?.DeleteFile(placeholderFullPath);
      return;
    }

    const inputElement: HTMLInputElement = this.createInputChild(rowElement);
    this.hideElement(nameElement);

    const saveName = () => {
      const name: string | undefined = inputElement.value?.trim();
      if (!name)
        return;

      const node: FileSystemNode | undefined = this.tree?.Find(`${path}/${name}`);
      if (node) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `The ${type === 'directory' ? 'directory' : 'file'} with the same name already exists in this folder.`
        });
        return;
      }

      this.sendMessage(
        type === 'directory' ? EditorEvent.CreateDir : EditorEvent.CreateFile,
        { path: `${path}/${name}` }
      );
    };

    const listeners: Array<() => void> = [
      this._renderer.listen(inputElement, 'blur', () => {
        saveName();
        cleanup();
      }),
      this._renderer.listen(rowElement, 'keydown', (event) => {
        if (event.key === 'Enter') {
          saveName();
          cleanup();
        }

        if (event.key === 'Escape') {
          cleanup();
        }
      })
    ];

    const cleanupListeners = () => {
      listeners.forEach((unlisten) => unlisten());
      listeners.length = 0;
    };

    const cleanup = () => {
      cleanupListeners();
      this.tree?.DeleteFile(placeholderFullPath);
      this.isRenaming = false;
    };

    this._cdr.detectChanges();
    inputElement.focus();
  }

  private handleWebSocketMessages(): void {
    this.websocketMessageSub = this.data.ws.messages$.subscribe(resp => {
      if (resp.type == Type.InitTree) {
        this.tree = new Tree(resp.init_tree ?? []);
        return;
      }

      if (resp.file_event && resp.file_event.type === FileEventType.Create) {
        this.tree?.AddFile(resp.file_event.tree);
        requestAnimationFrame(() => this.openDir(resp.file_event!.tree.path!));
        return;
      }

      if (
        resp.file_event &&
				resp.file_event.tree.path &&
				resp.file_event.type === FileEventType.DeleteFile) {
        this.tree?.DeleteFile(resp.file_event.tree.path);
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.isExerciseStarted = true;
      }

      if (
        resp.exercise?.validationTestsStatus == Status.Completed ||
				resp.exercise?.environmentStatus == Status.Canceled ||
				resp.loading_message ||
				resp.fatal_error) {
        this.isExerciseStarted = false;
      }
    });
  }
}
