import { Injectable } from '@angular/core';
import { Client, Server } from 'mock-socket';
import { Response, Message, Status, Type, Command } from 'src/app/exercise/exercise.component';
import { Node } from 'src/app/exercise/components/sidebar/components/file-watcher/models/node.interface';
import { environment } from 'src/environments/environment';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { GetCurrentTime } from 'src/app/shared/service/time';
import { AuthService } from 'src/app/auth/auth.service';
import { ExerciseStatus } from 'src/app/models/exercise-status.model';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';

// Terminal constants
const MIN_PRINTABLE_CHAR = String.fromCharCode(0x20);
const MAX_PRINTABLE_CHAR = String.fromCharCode(0x7E);
const MIN_EXTENDED_CHAR = '\u00a0';

const ENTER_KEY = '\r';
const BACKSPACE_KEY = '\u007F';
const BACKSPACE_OUTPUT = '\b \b';

// Terminal commands
const commands: Map<string, { output: string }> = new Map([
  ['ls', { output: 'input.txt  task.py' }],
  ['ll', { output: '.[1;33mr[0m[38;5;244m--[33mr[38;5;244m--[33mr[38;5;244m--[0m [1;32m6.9[0m[32mk[0m root root [34m 4 Dec 08:08[0m input.txt\r\n.[1;33mr[31mw[0m[38;5;244m-[33mr[31mw[38;5;244m-[33mr[31mw[38;5;244m-[0m  [1;32m665[0m root root [34m 4 Dec 08:08[0m task.py' }]
]);

@Injectable({
  providedIn: 'root'
})
export class DemoWebsocketServerService {
  private server: Server | null = null;
  private webSocketConnection: Client | null = null;
  private intervalId: NodeJS.Timeout | null = null;

  // Exercise state variables
  private currentCommand = '';
  private hintsUsedCount = 0;
  private remainingTime = 0;
  private validationCount = 0;
  private status = '';
  private completionTime = '';
  private isPlanFinished = false;

  private exerciseId = '';
  private assignedPlanId = '';
  private exerciseStatus: ExerciseStatus | null = null;

  // Loading messages
  private readonly installationMessage: string = 'Installing environment...';
  private readonly validationMessage: string = 'Validating...';
  private readonly restartMessage: string = 'Uninstalling environment...';

  // Terminal messages
  private readonly initialTerminalMessage: string = '[0m[27m[24m[J[36mdev-skill[39m [33m[[39m[32m~[39m[33m][39m [37m>[39m [K[63C[31m[39m[63D[?2004h';
  private readonly terminalResetSequence: string = '\u001b[?2004l\r\r\n';

  // Default file watcher tree
  private readonly initTree: Node[] = [
    { name: 'input.txt' },
    { name: 'task.py' }
  ];

  // Fake exercise hints
  private readonly hints: string[] = [
    'This is a placeholder hint for demonstration purposes.',
    'Hints will be available when you start real tasks.',
    'You are in demo mode. No hints are available for this task.'
  ];

  constructor(
    private dynamodbService: DynamoDBService,
    private authService: AuthService
  ) { }

  public async startServer(exerciseId: string, assignedPlanId: string): Promise<void> {
    this.server = new Server(environment.WebSocketEndpoint);
    this.exerciseId = exerciseId;
    this.assignedPlanId = assignedPlanId;

    this.exerciseStatus = await this.dynamodbService.getUserExerciseStatus(
      this.assignedPlanId,
      this.exerciseId,
      this.authService.user.email
    );

    this.hintsUsedCount = this.exerciseStatus?.usedHintsCount ?? 0;
    this.remainingTime = this.exerciseStatus?.remainingTime ?? 0;
    this.validationCount = this.exerciseStatus?.validationCount ?? 0;
    this.status = this.exerciseStatus?.status ?? Status.NotStarted;

    this.server.on('connection', async connection => {
      this.webSocketConnection = connection;
      await this.processInstallationSteps();
      connection.on('message', data => this.onMessage(data.toString()));
      connection.on('close', async () => await this.close());
    });
  }

  private async onMessage(messageData: string): Promise<void> {
    const message: Message = JSON.parse(messageData);

    switch (message.command) {
    case Command.Validate:
      await this.handleValidationCommand();
      return;
    case Command.Restart:
      await this.handleRestartCommand();
      return;
    case Command.Cancel:
      await this.handleExerciseCancelCommand();
      return;
    case Command.GetHint:
      await this.handleHintCommand();
      return;
    }

    if (message.terminal?.input)
      this.handleTerminalInput(message.terminal.input);
  }

  private handleTerminalInput(terminalInput: string): void {
    if (terminalInput === ENTER_KEY) {
      this.executeTerminalCommand(this.currentCommand);
      this.currentCommand = '';
      return;
    }

    if (terminalInput === BACKSPACE_KEY) {
      this.handleBackspaceInput();
      return;
    }

    if (terminalInput >= MIN_PRINTABLE_CHAR && terminalInput <= MAX_PRINTABLE_CHAR || terminalInput >= MIN_EXTENDED_CHAR)
      this.appendTerminalCharacter(terminalInput);
  }

  private handleBackspaceInput(): void {
    if (this.currentCommand.length > 0) {
      const response: Response = {
        type: Type.Terminal,
        terminal: { status: Status.Running, output: BACKSPACE_OUTPUT }
      };
      this.sendResponse(response);
      this.currentCommand = this.currentCommand.slice(0, -1);
    }
  }

  private appendTerminalCharacter(char: string): void {
    const response: Response = {
      type: Type.Terminal,
      terminal: { status: Status.Running, output: char }
    };
    this.currentCommand += char;
    this.sendResponse(response);
  }

  private async handleHintCommand(): Promise<void> {
    if (this.hintsUsedCount < this.hints.length) {
      const hintResponse: Response = {
        type: Type.Hint,
        hint: {
          text: this.hints[this.hintsUsedCount],
          is_last: this.hintsUsedCount + 1 === this.hints.length
        }
      };

      this.sendResponse(hintResponse);
      this.hintsUsedCount++;
      await this.updateExerciseStatus();
    }
  }

  private async handleExerciseCancelCommand(): Promise<void> {
    const exerciseStatusResponse: Response = {
      type: Type.ExerciseStatus,
      exercise: {
        validationTestsStatus: 'Not Started',
        environmentStatus: 'Canceled'
      }
    };
    this.sendResponse(exerciseStatusResponse);

    if (this.intervalId)
      clearInterval(this.intervalId);

    this.status = Status.Canceled;
    this.completionTime = GetCurrentTime();
    this.isPlanFinished = await this.isPlanCompleted();
    await this.updateExerciseStatus();
  }

  private async handleValidationCommand(): Promise<void> {
    await this.sendLoadingMessage(this.validationMessage, 4000);

    const response: Response = {
      type: Type.Terminal,
      exercise: {
        validationTestsStatus: 'Completed',
        validation_message: 'All checks passed'
      }
    };
    this.sendResponse(response);

    if (this.intervalId)
      clearInterval(this.intervalId);

    this.validationCount++;
    this.status = Status.Completed;
    this.completionTime = GetCurrentTime();
    this.isPlanFinished = await this.isPlanCompleted();
    await this.updateExerciseStatus();
  }

  private async handleRestartCommand(): Promise<void> {
    this.currentCommand = '';
    if (this.intervalId)
      clearInterval(this.intervalId);

    await this.sendLoadingMessage(this.restartMessage, 2500);
    await this.processInstallationSteps();
  }

  private async processInstallationSteps(): Promise<void> {
    await this.sendLoadingMessage(this.installationMessage, 3500);

    this.sendResponse({
      type: Type.Terminal,
      exercise: { environmentStatus: Status.Running, validationTestsStatus: Status.NotStarted }
    });

    this.sendResponse({
      type: Type.Terminal,
      terminal: { status: Status.Created }
    });

    this.initializeExerciseTimer();
    this.handleFileWatcherInitResponse();
    this.handleHintsInitResponse();

    this.status = Status.Running;
    await this.updateExerciseStatus();

    await this.delay(100);
    const responseMsg = this.createTerminalMessage(this.initialTerminalMessage);
    this.sendResponse(responseMsg);
  }

  private handleFileWatcherInitResponse(): void {
    const response: Response = { type: Type.InitTree, init_tree: this.initTree };
    this.sendResponse(response);
  }

  private handleHintsInitResponse(): void {
    for (let i = 0; i < this.hintsUsedCount; i++) {
      const hintResponse: Response = {
        type: Type.Hint,
        hint: {
          text: this.hints[i],
          is_last: this.hintsUsedCount === this.hints.length
        }
      };
      this.sendResponse(hintResponse);
    }
  }

  private initializeExerciseTimer(): void {
    const resp: Response = { type: Type.Timer, timer: this.remainingTime };
    this.sendResponse(resp);

    let updateIntervalSeconds = 60;

    this.intervalId = setInterval(async () => {
      this.remainingTime -= 1;
      updateIntervalSeconds -= 1;

      if (this.remainingTime === 0) {
        await this.handleTimeIsOver();
        return;
      }

      if (updateIntervalSeconds === 0) {
        const resp: Response = { type: Type.Timer, timer: this.remainingTime };
        this.sendResponse(resp);
        await this.updateExerciseStatus();
        updateIntervalSeconds = 60;
      }
    }, 1000);
  }

  private async handleTimeIsOver(): Promise<void> {
    const resp: Response = {
      type: Type.Terminal,
      exercise: {
        validationTestsStatus: 'Not Started',
        environmentStatus: 'Time is over'
      }
    };

    this.sendResponse(resp);
    
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.status = Status.TimeIsOver;
    this.completionTime = GetCurrentTime();
    this.isPlanFinished = await this.isPlanCompleted();
    await this.updateExerciseStatus();
  }

  private async isPlanCompleted(): Promise<boolean> {
    const plan: AssignedPlan = await this.dynamodbService.getAssignedPlan(
      this.authService.user.email,
      this.exerciseStatus?.interviewerCompanyId ?? '',
      this.assignedPlanId
    );
    return plan.exerciseCount - 1 === plan.completedTasksCount + plan.canceledTasksCount + plan.timeOverTasksCount;
  }

  private async updateExerciseStatus(): Promise<void> {
    if (!this.exerciseStatus) return;

    await this.dynamodbService.saveDemoExerciseStatus({
      AssignedPlanId: this.assignedPlanId,
      ExerciseId: this.exerciseId,
      CompanyId: this.exerciseStatus.interviewerCompanyId,
      UserEmail: this.authService.user.email,
      StartTime: this.exerciseStatus.startTime ?? GetCurrentTime(),
      CompletionTime: this.completionTime,
      ExerciseStatus: this.status,
      ValidationCount: this.validationCount,
      UsedHintsCount: this.hintsUsedCount,
      RemainingTime: this.remainingTime,
      ExecutionTime: this.exerciseStatus.maxExecutionTime - this.remainingTime,
      IsPlanFinished: this.isPlanFinished,
    });
  }

  private async sendLoadingMessage(loading_message: string, delayMs: number): Promise<void> {
    const message = { type: Type.Terminal, loading_message };
    this.sendResponse(message);
    await this.delay(delayMs);
  }

  private createTerminalMessage(text: string): Response {
    return { type: Type.Terminal, terminal: { status: Status.Running, output: text } };
  }

  private executeTerminalCommand(commandInput: string): void {
    const command = commandInput.trim().split(' ')[0];
    if (command.length === 0) {
      this.displayPrompt();
      return;
    }

    this.sendResponse(this.createTerminalMessage(this.terminalResetSequence));
    if (commands.has(command)) {
      const output = commands.get(command)!.output;
      this.sendResponse(this.createTerminalMessage(output));
    } else {
      this.sendResponse(this.createTerminalMessage(`zsh: command not found: ${command}`));
    }

    this.displayPrompt();
  }

  private displayPrompt(): void {
    this.currentCommand = '';
    const message: Response = this.createTerminalMessage(`\r\n${this.initialTerminalMessage}`);
    this.sendResponse(message);
  }

  private sendResponse(message: Response): void {
    this.webSocketConnection?.send(JSON.stringify(message));
  }

  private async close(): Promise<void> {
    await this.updateExerciseStatus();

    this.currentCommand = '';
    this.webSocketConnection?.close();
    this.webSocketConnection = null;

    this.server?.close();
    this.server?.stop();
    this.server = null;

    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private async delay(delayMs: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, delayMs));
  }
}
