<div *ngIf="isDemoMode && !isClosed"
    class="flex align-items-center justify-content-between gap-3 w-100 bg-red-600 h-auto absolute bottom-0 p-2 pl-4 pr-4 text-white">
    <div>
        <span>
            You are currently using the demo version of the platform. To access all features, please
        </span>
        <a (click)="openSupportDialog()" class="text-white cursor-pointer underline">contact us</a>
        <span>&nbsp;for further details.</span>
    </div>
    <span class="material-icons cursor-pointer" (click)="isClosed = true">
        close
    </span>
</div>