import { Injectable } from '@angular/core';
import { DynamoDBService } from '../dynamodb.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DemoModeService {
  public isDemo!: Promise<boolean>;
  
  private isDemoSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public isDemo$: Observable<boolean> = this.isDemoSubject.asObservable();

  constructor(
    private _dynamodb: DynamoDBService
  ) {
    this.updateDemoModeStatus();
  }

  public updateDemoModeStatus(): void {
    this.isDemo = this._dynamodb.isDemoMode()
      .then(isDemo => {
        this.isDemoSubject.next(isDemo); 
        return isDemo;
      })
      .catch(() => { return false; });
    this._dynamodb.isDemo = this.isDemo;
  }
}
