<div class="wrapper">
    <ng-container *ngIf="plan === undefined || plan?.exerciseStatuses?.length === 0">
        <ng-container *ngTemplateOutlet="loadingStatus"></ng-container>
    </ng-container>

    <ng-container *ngIf="plan === null || plan?.exerciseStatuses === null">
        <ng-container *ngTemplateOutlet="noData"></ng-container>
    </ng-container>

    <ng-container *ngIf="plan && plan.exerciseStatuses && plan.exerciseStatuses.length > 0">
        <h4>Plan Name: {{plan.planName}}</h4>

        <div id="scrollTo" class="info-container">
            <div class="title">
                <h3>Plan Description</h3>
            </div>
            <span>{{plan.planDescription}}</span>
        </div>
    
        <div class="info-container">
            <div class="title">
                <h3>Exercises</h3>
            </div>

            <div class="exercise-container"
                *ngFor="let exercise of plan.exerciseStatuses">
                <img [src]="exercise.picture.url">
                <ul>
                    <li><h3>{{exercise.exerciseName}}</h3></li>
                    <li>Difficulty: <span>{{exercise.exerciseDifficulty}}</span></li>
                    <li>Max Execution time: <span>{{exercise.maxExecutionTime | humanaizeSeconds}}</span></li>
                    <li>Used Hints Count: <span>{{exercise.usedHintsCount}}</span></li>
                    <li>Exercise Status: 
                        <span [ngStyle]="{'color': exercise.textColor}">
                            {{exercise.status}}
                        </span>
                    </li>
                </ul>

                <button type="button" 
                  [disabled]="!exercise.isRunning && !exercise.isNotStarted || (!plan.isNotStarted && !plan.isRunning)"
                  (click)="openExercise(exercise)">
                    <i class="material-icons-round">
                        play_circle_outline
                    </i>
                </button>                        
            </div>
        </div>
    
        <div class="info-container">
            <div class="title">
                <h3>Time</h3>
            </div>
            <div>End Date: {{plan.deadline | date : 'MMM d, y, HH:mm'}}</div>
            <div>Remaining Time: {{plan.remainingTime | humanaizeSeconds}}</div>
        </div>

        <div class="info-container">
            <div class="title">
                <h3>Company Details</h3>
            </div>
            <div>Interviewer: {{plan.interviewer?.fullName}}</div>
            <div>Company Name: {{plan.interviewerCompanyName}}</div>
        </div>


        <div class="info-container">
            <div class="action flex">
                <button
                  *ngIf="plan.isNotStarted || plan.isFinished || plan.isCanceled || plan.isDeadlinePassed"
                  class="delete mr-4" 
                  (click)="rejectPlan(plan); $event.stopPropagation()">
                    <i class="material-icons-outlined">delete_outline</i>
                    {{plan.isFinished || plan.isCanceled || plan.isDeadlinePassed ? 'Delete' : 'Reject' }}
                </button>
                <button  
                  *ngIf="!plan.isFinished && !plan.isNotStarted && !plan.isCanceled && !plan.isDeadlinePassed"
                  class="border-1 border-yellow-600 text-yellow-600 pr-3 pl-3 mr-4" 
                  (click)="finish(plan); $event.stopPropagation()">
                    <i class="material-icons-outlined">outlined_flag</i>
                   Finish
                </button>
                <button  
                  *ngIf="plan.isWelcomePlan && plan.isFinished && !plan.isPaid && !isDemoMode"
                  class="border-1 border-blue-600 text-blue-600 pr-3 pl-3" 
                  routerLink="/payment"
                  [queryParams]="{email: user.email, assigned_plan_id: plan.id}">
                    <i class="material-icons-outlined">credit_card</i>
                    Support Us
              </button>
            </div>
        </div>
    </ng-container>
</div>

<p-confirmDialog 
  #dialog 
  [breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
  [style]="{width: '500px'}">
    <ng-template pTemplate="footer">
        <simple-button (click)="dialog.reject()" class="mr-3">No</simple-button>
        <simple-button (click)="dialog.accept()">Yes</simple-button>
    </ng-template>
</p-confirmDialog>

<ng-template #noData>
    <div class="no-data mt-4">
        <h3>
            You currently have no plans assigned to you.
        </h3>
    </div>
</ng-template>

<ng-template #loadingStatus>
    <div class="center">
        <p-skeleton height="18px" width="240px"></p-skeleton>
    </div>
    <div class="info-container">
        <div class="title">
            <h3>Plan Description</h3>
            <app-help [helpMessage]="helpMessageEnum.Exercises"></app-help>
        </div>
        <ul class="skeleton-list">
            <li><p-skeleton height="10px" width="100%"></p-skeleton></li>
            <li><p-skeleton height="10px" width="100%"></p-skeleton></li>
            <li><p-skeleton height="10px" width="100%"></p-skeleton></li>
            <li><p-skeleton height="10px" width="40%"></p-skeleton></li>
        </ul>

        <div class="title">
            <h3>Exercises</h3>
        </div>
        <div class="exercise-container"
          *ngFor="let item of skeletonItems">
            <p-skeleton height="100px" width="100px"></p-skeleton>
            <ul class="skeleton-list">
                <li><p-skeleton height="14px" width="150px"></p-skeleton></li>
                <li><p-skeleton height="10px" width="100px"></p-skeleton></li>
                <li><p-skeleton height="10px" width="120px"></p-skeleton></li>
                <li><p-skeleton height="10px" width="80px"></p-skeleton></li>
                <li><p-skeleton height="10px" width="140px"></p-skeleton></li>
            </ul>
            <button><p-skeleton shape="circle" height="40px" width="40px"></p-skeleton></button>
        </div>

        <div class="title">
            <h3>Time</h3>
        </div>
        <ul class="skeleton-list">
            <li><p-skeleton height="12px" width="200px"></p-skeleton></li>
            <li><p-skeleton height="12px" width="260px"></p-skeleton></li>
        </ul>

        <div class="title">
            <h3>Company Details</h3>
        </div>
        <ul class="skeleton-list">
            <li><p-skeleton height="12px" width="220px"></p-skeleton></li>
            <li><p-skeleton height="12px" width="250px"></p-skeleton></li>
        </ul>
    </div>
</ng-template>