import { Terminal } from 'xterm';
import { FitAddon, ITerminalDimensions } from 'xterm-addon-fit';
import { BehaviorSubject, Observable } from 'rxjs';

export class TerminalService {
  private terminal: Terminal;
  private fitAddon: FitAddon | null = null;
  private interval: NodeJS.Timeout | null = null;
  private terminalElement: HTMLElement | null = null;
  private isTerminalOpen = false;
  private _rows: number | null = null;
  private _cols: number | null = null;
  private resizeObserver: ResizeObserver | null = null;

  private inputEventSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public inputEvent$: Observable<string | null> = this.inputEventSubject.asObservable();

  private resizeEventSubject: BehaviorSubject<TerminalSize | null> = new BehaviorSubject<TerminalSize | null>(null);
  public resizeEvent$: Observable<TerminalSize | null> = this.resizeEventSubject.asObservable();

  constructor(
    isDarkTheme = true
  ) {
    this.terminal = new Terminal({
      theme: isDarkTheme ? DarkTheme : WhiteTheme,
      fontSize: 14,
      fontFamily: 'Consolas, "Courier New", monospace',
      cursorBlink: true,
    });
  }

  get proposeDimensions(): ITerminalDimensions | undefined {
    return this.fitAddon?.proposeDimensions();
  }

  set rows(value: number) {
    this._rows = value;
  }

  set cols(value: number) {
    this._cols = value;
  }

  private changeTerminalFontSize(newFontSize: number): void {
    this.terminal.options.fontSize = newFontSize;
    this.resize();
  }

  public resize(): void {
    try {
      this.fitAddon && this.fitAddon.fit();
    } catch (err) {
      console.log(err);
    }

    if (this._cols && this._rows) {
      this.terminal.resize(this._cols, this._rows);
      return;
    }

    const size = this.fitAddon?.proposeDimensions();

    if (this._rows) {
      this.terminal.resize(size?.cols ?? this.terminal.cols, this._rows);
      return;
    }

    if (this._cols) {
      this.terminal.resize(this._cols, size?.rows ?? this.terminal.rows);
      return;
    }
  }

  public resetTerminal(): void {
    this.terminal.reset();
  }

  // Clear terminal and loading interval if it exists
  public clearTerminal(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.changeTerminalFontSize(14);
    this.resetTerminal();
  }

  public create(): void {
    this.fitAddon = new FitAddon();
    this.terminal.loadAddon(this.fitAddon);

    this.terminalElement = document.getElementById('terminal');
    this.terminal.open(this.terminalElement!);

    this.configure();
    this.terminal.focus();
    this.isTerminalOpen = true;
  }

  private configure(): void {
    this.enableResize();
    this.setupResizeEventHandler();
    this.setupKeyEventHandler();
    this.handleCopyPasteEvent();
  }

  public enableResize(): void {
    if (this.resizeObserver)
      return;

    this.resizeObserver = new ResizeObserver(() => {
      try {
        this.resize();
      } catch (err) {
        console.log(err);
      }
    });
    this.resizeObserver.observe(this.terminalElement!);
  }

  public disableResize(): void {
    this.resizeObserver?.disconnect();
    this.resizeObserver = null;
  }

  private handleCopyPasteEvent(): void {
    // Listen copy/paste events
    this.terminal.textarea!.addEventListener('paste', (event) => {
      const text = event.clipboardData!.getData('text');
      this.emitInputEvent(text);
      event.preventDefault();
    });

    this.terminal.textarea!.addEventListener('copy', (event) => {
      event.clipboardData!.setData('text/plain', this.terminal.getSelection());
      event.preventDefault();
    });
  }

  public write(input: string, callback?: () => void): void {
    this.terminal.write(input, callback);
  }

  public writeln(input: string): void {
    this.terminal.writeln(input);
  }

  public dispose(): void {
    this.removeEventListeners();
    this.terminal.dispose();
    this.isTerminalOpen = false;
  }

  public scrollToTop(): void {
    this.terminal.scrollToTop();
  }

  private setupResizeEventHandler(): void {
    let debounceTimer: NodeJS.Timeout;
    const debounceInterval = 500;

    this.terminal.onResize(event => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        this.emitResizeEvent(event.cols, event.rows);
      }, debounceInterval);
    });
  }

  private setupKeyEventHandler(): void {
    this.terminal.onKey((key) => {
      this.emitInputEvent(key.key);
    });
  }

  private removeEventListeners(): void {
    if (this.terminal.textarea && this.terminal.textarea.removeAllListeners) {
      this.terminal.textarea.removeAllListeners('copy');
      this.terminal.textarea.removeAllListeners('paste');
    }
  }

  private emitInputEvent(input: string): void {
    this.inputEventSubject.next(input);
  }

  public emitResizeEvent(cols: number, rows: number): void {
    this.resizeEventSubject.next({ cols, rows });
  }

  public changeTheme(theme: string): void {
    this.terminal.options.theme = theme == 'light' ? WhiteTheme : DarkTheme;
  }

  public changeFontSize(size: number): void {
    this.terminal.options.fontSize = size;
  }

  public get isOpen(): boolean {
    return this.isTerminalOpen;
  }
}

const WhiteTheme = {
  background: 'white',
  foreground: '#000000',
  cursor: '#444444',
  selectionBackground: '#999999',
  black: '#4c4c4c',
  red: '#db0707',
  green: '#38761d',
  yellow: '#bf9000',
  blue: '#0b5394',
  magenta: '#cf04cf',
  cyan: '#0217cf',
  white: '#000000',
  brightBlack: '#d1cdcd',
  brightRed: '#FF0000',
  brightGreen: '#0a6e03',
  brightYellow: '#e3aa00',
  brightBlue: '#116fc2',
  brightMagenta: '#ff00ff',
  brightCyan: '#031cfc',
  brightWhite: '#d1cdcd',
};

const DarkTheme = { background: '#2c2c2e' };

export interface TerminalSize {
  cols: number
  rows: number
}