import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CookiesService {

  constructor(
        private cookieService: CookieService
  ) { }

  public removeCognitoCookies(): void {
    const cookies = this.cookieService.getAll();
    const cognitoCookies = Object.keys(cookies).filter(cookieName => cookieName.includes('Cognito') || cookieName.includes('amplify'));
    cognitoCookies.forEach(cookieName => this.deleteCookie(cookieName));
  }

  public deleteCookie(cookieName: string): void {
    this.cookieService.delete(cookieName, '/', environment.cookieConfig.cookie.domain);
  }
}