import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ExerciseDataService } from '../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';
import { DemoExerciseDataService } from '../../demo/services/demo-exercise-data/demo-exercise-data.service';
import { IExerciseDataService } from '../../demo/models/exercise-data-interface';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy, OnInit {
  private data!: IExerciseDataService;
  private exerciseSubscription: Subscription = new Subscription();
  public itemsMap: Map<string, Item> = new Map<string, Item>([
    ['description', { icon: 'description', isOpen: true, isBottom: false, tooltip: 'Description', open: (item) => this.toggleItem(item) }],
    ['hints', { icon: 'lightbulb', isOpen: false, isBottom: false, tooltip: 'Hints', open: (item) => this.toggleItem(item) }],
    ['help', { icon: 'help_outline', isOpen: false, isBottom: false, tooltip: 'Help', open: (item) => this.toggleItem(item) }],
    ['support', { icon: 'support', isOpen: false, shouldOpen: true, isBottom: true, tooltip: 'Support', open: () => this.support() }],
    ['settings', { icon: 'settings', isOpen: false, isBottom: true, tooltip: 'Settings', open: (item) => this.toggleItem(item) }]
  ]);

  constructor(
    private _exerciseData: ExerciseDataService,
    private _demoExerciseData: DemoExerciseDataService,
    private _dialogService: DialogService,
    private _demoModeService: DemoModeService
  ) { }

  public async ngOnInit() {
    this.data = await this._demoModeService.isDemo ? this._demoExerciseData : this._exerciseData;

    this.exerciseSubscription = this.data.exercise$.subscribe(data => {
      if (!data) {
        return;
      }

      if (data.needCodeEditor) {
        this.itemsMap.set('files', { icon: 'folder_copy', isOpen: false, isBottom: false, tooltip: 'Files', open: (item) => this.toggleItem(item) });
      }
    });
  }

  public ngOnDestroy() {
    this.exerciseSubscription.unsubscribe();
  }

  public toggleItem(selectedItem: Item): void {
    this.itemsMap.forEach(item => {
      item.isOpen = (item === selectedItem);
    });
  }

  public isOpen(itemName: string): boolean {
    const item = this.itemsMap.get(itemName);
    return item ? item.isOpen : false;
  }

  public support() {
    this._dialogService.open(SupportDialogComponent, {
      width: '550px',
      baseZIndex: 10000,
      showHeader: true,
      dismissableMask: true,
      header: 'Submit Ticket'
    });
  }
}

export interface Item {
  icon: string
  isOpen: boolean
  isBottom: boolean
  tooltip: string
  shouldOpen?: boolean
  open: (item: Item) => void
}
