import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { PlanListWidgetDataService } from './service/plan-list-widget-data.service';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-exercise-plans-list-widget',
  templateUrl: './exercise-plans-list-widget.component.html',
  styleUrls: ['./exercise-plans-list-widget.component.scss']
})
export class ExercisePlansListWidgetComponent implements AfterViewInit, OnDestroy {
  @ViewChild('planList') plansContainer: ElementRef | null = null;

  public user: User;
  public skeletonArray: number[] = Array.from({ length: 3 });
  public plans: ExercisePlanSummary[] | null = null;
  private plansSubscription: Subscription = new Subscription;

  constructor(
    public widgetData: PlanListWidgetDataService,
    public auth: AuthService
  ) {
    this.user = auth.user;
  }

  ngOnDestroy(): void {
    this.plansSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subToPlanChanges();
    const plansCount = this.calculateListCapacity();
    this.widgetData.fetch(plansCount);
  }

  private subToPlanChanges(): void {
    this.plansSubscription.add(this.widgetData.data$.subscribe(plans => {
      if (plans !== null) {
        this.plans = plans;
      }
    }));
  }

  private calculateListCapacity(): number {
    const itemHeight = 55;
    const gap = 25;
    const containerHeight: number = this.plansContainer!.nativeElement.offsetHeight;
    return Math.floor(containerHeight / (itemHeight + gap));
  }
}

export interface ExercisePlanSummary {
  id: string;
  name: string;
  description: string;
  picture: string;
  deadline?: string;
}