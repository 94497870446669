<div class="wrapper">
    <app-header></app-header>
    <div class="main">
        <!-- Show time only if replay started -->
        <div class="time">{{ startReplay ? 'Time: ' + currentTimestamp : ''}}</div>
    
        <div class="viewport-container">
            <div class="error" *ngIf="error">{{error}}</div>
            <div class="loading" *ngIf="loadingMsg && !error">{{loadingMsg}}</div>
            <div id="terminal" [style.display]="!error && !loadingMsg && isCodeEditorClosed ? 'block': 'none'"></div>
            <ngx-monaco-editor
                [style.display]="isCodeEditorClosed ? 'none' : 'flex'"
                [style.position]="isCodeEditorClosed ? 'absolute': 'static'"
                [style.visibility]="isCodeEditorClosed ? 'hidden':'visible'"
                [options]="editorOptions" 
                (onInit)="onEditorInit($event)">
           </ngx-monaco-editor>
        </div>

        <!-- Button to open the info panel is displayed only when the panel is closed -->
        <div
          *ngIf="!isInfoShowed" 
          class="info-btn">
            <button
              pButton 
              type="button"
              icon="pi pi-question"
              class="p-button-rounded p-button-outlined text-white"
              (click)="isInfoShowed = !isInfoShowed">
            </button>
        </div>
    
        <!-- Show this block only if records exist -->
        <div 
          *ngIf="recordsExist && !error && !loadingMsg"
          class="info"
          [ngStyle]="{opacity: isInfoShowed ? 1 : 0, visibility: isInfoShowed ? 'visible' : 'hidden' }">

            <div class="flex align-content-center justify-content-between mb-4">
                <div class="table-title">Terminal records</div>
                <button 
                  class="material-icons-outlined close-btn"
                  (click)="isInfoShowed = !isInfoShowed">
                    close
                </button>
            </div>
            
            <table>
                <tbody>
                    <tr>
                        <td class="heading">Candidate Name</td>
                        <td class="cell">{{ candidateName }}</td>
                    </tr>
                    <tr>
                        <td class="heading">Exercise</td>
                        <td class="cell">{{ exercise?.userFriendlyName }}</td>
                    </tr>
                    <tr>
                        <td class="heading">Execution time</td>
                        <td class="cell">{{ exerciseStatus?.executionTime }}</td>
                    </tr>
                    <tr>
                        <td class="heading">Status</td>
                        <td class="cell">{{ exerciseStatus?.status }}</td>
                    </tr>
                    <tr>
                        <td class="heading">Difficulty</td>
                        <td class="cell">{{ exercise?.difficulty }}</td>
                    </tr>
                </tbody>
            </table>
            <h3>Description</h3>
            <div
              [innerHTML]="exercise!.description | markdown:exerciseStatus?.dynamicDescription">
            </div>
        </div>
    
        <div *ngIf="!error && !loadingMsg" class="player">
            <exercise-button
              id="pause-button"
              (click)="togglePause()">
              {{ isPaused ? 'PLAY' : 'PAUSE' }}
            </exercise-button>
            <div class="playback">
                <input id="playback-slider" type="range" [(ngModel)]="timeIndex" [min]="0" [max]="recordLength - 1"
                    [step]="1" (change)="onSliderChange()">
            </div>
            <div class="speed">
                <span class="speed-label">Speed: </span>
                <button class="speed-button" (click)="changeSpeed(-1)">-</button>
                <span class="speed-label">{{ speed }}</span>
                <button class="speed-button" (click)="changeSpeed(1)">+</button>
            </div>
        </div>
    </div>
</div>