import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { User } from 'src/app/user.module';
import { ExercisePlanSummary } from '../exercise-plans-list-widget.component';
import { InterviewerExercisePlan } from 'src/app/exercise.module';
import { environment } from 'src/environments/environment';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';

@Injectable({
  providedIn: 'root'
})
export class PlanListWidgetDataService {
  private dataSubject: BehaviorSubject<ExercisePlanSummary[] | null> = new BehaviorSubject<ExercisePlanSummary[] | null>(null);
  public data$: Observable<ExercisePlanSummary[] | null> = this.dataSubject.asObservable();
  private user: User;

  constructor(
    private auth: AuthService,
    private dynamodb: DynamoDBService,
    private messageService: MessageService
  ) {
    this.user = this.auth.user;
  }


  public async fetch(plansCount: number): Promise<void> {
    let data: ExercisePlanSummary[] =[];
    try {
      if (this.user.isInterviewer) {
        const plans = await this.dynamodb.getInterviewerExercisePlansForWidget(this.user.companyId, plansCount);
        data = this.convertToInterviewerExercisePlanSummary(plans);
      } else {
        const plans = await this.dynamodb.getUserExercisesPlansForWidget(this.user.email, plansCount);
        data = this.convertToUserExercisePlanSummary(plans);
      }
      this.dataSubject.next(data);
    } catch {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to fetch exercise plans. Please try again later.' });
      this.dataSubject.next(data);
    }
  }

  private convertToInterviewerExercisePlanSummary(plans: InterviewerExercisePlan[]): ExercisePlanSummary[] {
    return plans.map(plan => {
      return {
        name: plan.name,
        id: plan.id,
        picture: environment.DefaultPlanPicture,
        description: plan.description,
      };
    });
  }

  private convertToUserExercisePlanSummary(plans: AssignedPlan[]): ExercisePlanSummary[] {
    return plans.map(plan => {
      return {
        name: plan.planName,
        id: plan.id,
        picture: environment.DefaultPlanPicture,
        description: plan.planDescription ?? '',
        deadline: plan.deadline
      };
    });
  }
}
