import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { DemoExerciseDataService } from 'src/app/exercise/demo/services/demo-exercise-data/demo-exercise-data.service';
import { IExerciseDataService } from 'src/app/exercise/demo/models/exercise-data-interface';
import { DemoModeService } from 'src/app/demo/demo-mode.service';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit, OnDestroy {
  public data!: IExerciseDataService;
  public dynamicDescription: string | undefined;
  private websocketMessageSub: Subscription = new Subscription();

  constructor(
    private exerciseData: ExerciseDataService,
    private demoExerciseData: DemoExerciseDataService,
    private demoModeService: DemoModeService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.data = await this.demoModeService.isDemo ? this.demoExerciseData : this.exerciseData;
    this.handleWebsocketMessage();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  private handleWebsocketMessage(): void {
    this.websocketMessageSub = this.data.ws.messages$.subscribe(resp => {
      if (resp.description) {
        this.dynamicDescription = resp.description;
      }
    });
  }
}
