import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { DescriptionComponent } from './components/description/description.component';
import { HintComponent } from './components/hint/hint.component';
import { FileWatcherComponent } from './components/file-watcher/file-watcher.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxMonacoTreeModule } from 'ngx-monaco-tree';
import { SettingsComponent } from './components/settings/settings.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { HelpComponent } from './components/help/help.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    SidebarComponent,
    DescriptionComponent,
    HintComponent,
    FileWatcherComponent,
    SettingsComponent,
    HelpComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ProgressSpinnerModule,
    NgxMonacoTreeModule,
    DropdownModule,
    FormsModule,
    MenuModule,
    TooltipModule,
    ConfirmDialogModule
  ],
  exports: [
    SidebarComponent
  ]
})
export class SidebarModule { }
