import { environment } from 'src/environments/environment';
import { TaskStatus } from '../status-parser';
import { SecondsToHumanReadable } from '../shared/service/time';
import { Picture } from './picture.model';
import { GetStatusColor } from '../shared/service/ui';

export class ExerciseStatus {
  constructor(
    attributes: ExerciseStatusAttribute
  ) {
    this._assignedPlanId = attributes.assignedPlanID;
    this._userEmail = attributes.userEmail;
    this._exerciseID = attributes.exerciseID;
    this._exerciseName = attributes.exerciseName;
    this._exerciseDifficulty = attributes.exerciseDifficulty;
    this._validationCount = attributes.validationCount;
    this._status = attributes.status;
    this._usedHintsCount = attributes.usedHintsCount;
    this._remainingTime = attributes.remainingTime;
    this._maxExecutionTime = attributes.maxExecutionTime;
    this._startTime = attributes.startTime;
    this._completionTime = attributes.completionTime;
    this._sourceIPs = attributes.sourceIPs;
    this._urlID = attributes.urlID;
    this._planName = attributes.planName;
    this._deadline = attributes.deadline;
    this._dynamicDescription = attributes.dynamicDescription;
    this._textColor = GetStatusColor(attributes.status ?? 'black');
    this._isDeadlinePassed = this.checkIfDeadlinePassed(attributes.deadline);
    this._interviewerCompanyId = attributes.interviewerCompanyId;

    this._picture = new Picture(`${environment.ExercisePicturePath}${this._exerciseID}.jpeg`);

    this._isPlanCanceled = attributes.isPlanCanceled ?? false;
    this._isPlanFinished = attributes.isPlanFinished ?? false;
    this._isPlanRejected = attributes.isPlanRejected ?? false;
  }

  private _assignedPlanId: string;
  private _userEmail: string;
  private _exerciseID: string;
  private _textColor: string;
  private _exerciseName: string | undefined;
  private _exerciseDifficulty: string | undefined;
  private _status: string | undefined;
  private _validationCount: number | undefined;
  private _usedHintsCount: number | undefined;
  private _remainingTime: number | undefined;
  private _maxExecutionTime: number | undefined;
  private _startTime: string | undefined;
  private _completionTime: string | undefined;
  private _urlID: string | undefined;
  private _planName: string | undefined;
  private _sourceIPs: string[] | undefined;
  private _deadline: string | undefined;
  private _dynamicDescription: string | undefined;
  private _interviewerCompanyId: string | undefined;

  private _isDeadlinePassed: boolean;
  private _isPlanCanceled: boolean;
  private _isPlanFinished: boolean;
  private _isPlanRejected: boolean;

  private _picture: Picture;
  private _executionTime: string | undefined;
  private _base64UserEmail: string | undefined;

  public get assignedPlanID(): string {
    return this._assignedPlanId;
  }

  public get userEmail(): string {
    return this._userEmail;
  }

  public get picture(): Picture {
    return this._picture;
  }

  public get urlID(): string | undefined {
    return this._urlID;
  }

  public get exerciseID(): string {
    return this._exerciseID;
  }

  public get exerciseName(): string | undefined {
    return this._exerciseName;
  }

  public get exerciseDifficulty(): string | undefined {
    return this._exerciseDifficulty;
  }

  public get planName(): string | undefined {
    return this._planName;
  }

  public get validationCount(): number {
    return this._validationCount ?? 0;
  }

  public get status(): TaskStatus | undefined {
    return this._status as TaskStatus;
  }

  public get textColor(): string {
    return this._textColor;
  }

  public get usedHintsCount(): number {
    return this._usedHintsCount ?? 0;
  }

  public get remainingTime(): number {
    return this._remainingTime ?? 0;
  }

  public get maxExecutionTime(): number {
    return this._maxExecutionTime ?? 0;
  }

  public get startTime(): string | undefined {
    return this._startTime;
  }

  public get completionTime(): string | undefined {
    return this._completionTime;
  }

  public get deadline(): string | undefined {
    return this._deadline;
  }

  public get dynamicDescription(): string {
    return this._dynamicDescription ?? '';
  }

  public get isDeadlinePassed(): boolean {
    return this._isDeadlinePassed;
  }

  public get isCanceled(): boolean {
    return this.status == TaskStatus.CANCELED;
  }

  public get isPlanRejected(): boolean {
    return this._isPlanRejected;
  }

  public get isPlanFinished(): boolean {
    return this._isPlanFinished;
  }

  public get isPlanCanceled(): boolean {
    return this._isPlanCanceled;
  }

  public get interviewerCompanyId(): string {
    return this._interviewerCompanyId ?? '';
  }

  public get isTimeIsOver(): boolean {
    return this.status === TaskStatus.TIME_IS_OVER;
  }

  public get isCompleted(): boolean {
    return this.status === TaskStatus.COMPLETED;
  }

  public get isNotStarted(): boolean {
    return this.status === TaskStatus.NOT_STARTED;
  }

  public get isRunning(): boolean {
    return this.status === TaskStatus.RUNNING;
  }

  public get base64UserEmail(): string | undefined {
    if (!this._base64UserEmail && this._userEmail) {
      this._base64UserEmail = btoa(this._userEmail);
    }
    return this._base64UserEmail;
  }

  public get executionTime(): string | undefined {
    if (this._executionTime === undefined &&
      this._remainingTime !== undefined &&
      this._maxExecutionTime !== undefined) {
      this._executionTime = SecondsToHumanReadable(this._maxExecutionTime - this._remainingTime);
    }
    return this._executionTime;
  }

  public get sourceIPs(): string[] | undefined {
    return this._sourceIPs;
  }

  private checkIfDeadlinePassed(utcDeadline: string | undefined): boolean {
    if (!utcDeadline)
      return false;

    const now = new Date();
    const deadline = new Date(utcDeadline);
    return now > deadline;
  }
}

interface ExerciseStatusAttribute {
  assignedPlanID: string;
  userEmail: string;
  exerciseID: string;
  exerciseName?: string;
  validationCount?: number;
  status?: string;
  usedHintsCount?: number;
  remainingTime?: number;
  maxExecutionTime?: number;
  startTime?: string;
  completionTime?: string;
  sourceIPs?: string[];
  exerciseDifficulty?: string;
  interviewerCompanyId?: string;
  deadline?: string;
  dynamicDescription?: string;
  isPlanCanceled?: boolean;
  isPlanFinished?: boolean;
  isPlanRejected?: boolean;

  urlID?: string;
  planName?: string;
}