import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplayComponent } from './components/replay.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    ReplayComponent
  ],
  imports: [
    CommonModule,
    MonacoEditorModule.forRoot(),
    SharedModule,
    FormsModule,
    ButtonModule
  ],
  exports: [
    ReplayComponent
  ]
})

export class ReplayModule { }
